import lodash from 'lodash';

/* eslint-disable no-continue, no-restricted-syntax */

const typeMapping = {
  string: String(),
  boolean: Boolean(),
  choice: Number(),
  integer: Number(),
  'image upload': Object(),
};

export default {
  djangoRestFrameworkMetadataParser(metadata) {
    const model = {};
    const metaKeys = Object.keys(metadata);
    for (const mKey of metaKeys) {
      if (Object.prototype.hasOwnProperty.call(metadata[mKey], 'type')) {
        let mType = metadata[mKey].type;
        if (mType === 'field') {
          if (Object.prototype.hasOwnProperty.call(metadata[mKey], 'child')) {
            mType = [];
          }
        } else if (mType === 'nested object') {
          if (Object.prototype.hasOwnProperty.call(metadata[mKey], 'children')) {
            mType = this.djangoRestFrameworkMetadataParser(metadata[mKey].children);
          }
        }
        if (typeof mType === 'object') {
          model[mKey] = mType;
        } else {
          model[mKey] = typeMapping[mType];
        }
      }
    }
    return model;
  },
  idifyObjects(keys, object) {
    const copy = lodash.cloneDeep(object);
    Object.keys(copy).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(copy[key], 'id')) {
        copy[key] = copy[key].id;
      }
    });
    return copy;
  },
};
