import Vue from 'vue';
import { ReadMessage } from '../../api/notify';

function checkMenuShadow(state) {
  if (!window.$) return;
  if (state.activePopups.length > 0) {
    window.$('.header').addClass('no-shadow');
  } else {
    window.$('.header').removeClass('no-shadow');
  }
}

export default {
  namespaced: true,
  state: {
    activePopups: [],
    poups: [],
    sideNotifications: [],
    sideNotificationsShowing: [],
    activeWindow: null,
  },
  getters: {
    popupsCount(state) {
      return state.activePopups.length;
    },
  },
  mutations: {
    setaActivePopups(state, popups) {
      state.activePopups = popups;
    },
    showMessage(state, message) {
      if (Number.isInteger(message.max_count)) {
        if (
          state.activePopups.filter(
            (x) => x.title === message.title && x.icon === message.icon && x.type === message.type
          ).length >= message.max_count
        )
          return;
      }
      state.activePopups.push(message);
      checkMenuShadow(state);
    },
    addPoup(state, message) {
      if (state.activePopups.length < 4) {
        state.activePopups.push(message);
      } else {
        state.poups.push(message);
      }
      // no-shadow
      checkMenuShadow(state);
    },
    deletePopup(state, popup) {
      const key = state.activePopups.findIndex((item) => item.id === popup.id);
      if (key < 0) return;
      state.activePopups.splice(key, 1);
      if (state.poups.length > 0) {
        state.activePopups.push(state.poups.shift());
      }
      checkMenuShadow(state);
    },
    addSideNotification(state, data) {
      if (state.sideNotificationsShowing.length < 4) {
        state.sideNotificationsShowing.push(data);
      } else {
        state.sideNotifications.push(data);
      }
    },
    readSideNotification(state, idx) {
      state.sideNotificationsShowing.splice(idx, 1);
      if (state.sideNotifications.length > 0) {
        state.sideNotificationsShowing.push(state.sideNotifications.shift());
      }
    },
    setWindow(state, data) {
      Vue.set(state, 'activeWindow', data);
    },
  },
  actions: {
    async readMessage(context, popup) {
      context.commit('deletePopup', popup);
      if (popup.id > 0) {
        await ReadMessage(popup.id);
      }
    },
  },
};
