export default {
  getAccountGraphql(login) {
    return new Promise((resolve, reject) => {
      const URL = `https://www.instagram.com/${login}/?__a=1`;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', URL, true);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const response = JSON.parse(xhr.responseText);
            resolve(response);
          } catch (e) {
            reject(new Error('Wrong JSON data in in instagram request!'));
          }
        } else {
          reject(new Error(xhr.statusText));
        }
      };
      xhr.onerror = () => {
        reject(new Error(xhr.statusText));
      };
      xhr.send();
    });
  },
  getAccountInfo(uid) {
    return new Promise((resolve, reject) => {
      const URL = `https://i.instagram.com/api/v1/users/${uid}/info/`;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', URL, true);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const response = { graphql: JSON.parse(xhr.responseText) };
            resolve(response);
          } catch (e) {
            reject(new Error('Wrong JSON data in in instagram request!'));
          }
        } else {
          reject(new Error(xhr.statusText));
        }
      };
      xhr.onerror = () => {
        reject(new Error(xhr.statusText));
      };
      xhr.send();
    });
  },
};
