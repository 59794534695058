import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.filter('formatDateTime', (value, format) => {
  if (!value) return;
  if (!format) format = 'DD.MM.YY HH:mm';
  return moment(value).format(format);
});

Vue.filter('beautifyNum', (val) => {
  if (val === undefined || val === null) return '';
  const valNumber = Number.parseFloat(val);
  if (!Number.isNaN(valNumber)) val = valNumber;
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
});
