<template>
  <div
    v-if="navButtons"
    @click="handleCarClick"
    class="mb-3 d-none d-sm-block"
    style="
      padding: 20px 1000px;
      padding-top: 8px;
      margin: 0 -1000px;
      margin-top: -18px;
      background: linear-gradient(0deg, rgb(242, 247, 250) 2%, rgb(255, 255, 255) 62%);
    "
  >
    <p class="gray-text fs-12 mb-3">Все сервисы</p>
    <div id="sw-container">
      <!--b-button @click="swp.slidePrev()" variant="link" class="mt-1 navigation-prev"></!--b-button-->
      <swiper ref="car">
        <swiper-slide :key="`${btn.id}mblnavbGD1`" v-for="btn in navButtons">
          <b-button
            class="m-1"
            :to="!btn.link && { name: btn.to, query: btn.query }"
            :href="btn.link"
            variant="link"
            style="
              border-radius: 25px;
              box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), inset 0px 0px 4px #f2f2f2;
              border: 1px solid #d5dfe4;
              border-radius: 25.5px;
            "
          >
            <img
              style="width: 95px; height: 95px"
              :src="btn.icon_desktop"
              :alt="btn.text"
              :disabled="btn.disabled"
              :title="btn.text"
            />
          </b-button>
          <p
            class="mt-2"
            style="
              width: 115px;
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: #192229;
            "
          >
            {{ btn.text }}
          </p>
        </swiper-slide>
      </swiper>
      <!--b-button @click="swp.slideNext()" variant="link" class="mt-1 navigation-next"></!--b-button-->
    </div>
  </div>
</template>

<script>
import ui from '@main/api/ui';

export default {
  data: () => ({
    navButtons: null,
    resized: true,
  }),
  computed: {
    swp() {
      return this.$refs.car.$swiper;
    },
  },
  methods: {
    handleCarClick() {
      this.resized = false;
    },
  },
  async mounted() {
    const buttons = await ui.buttons.activeServices.get();
    this.navButtons = buttons.results;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .navigation-next {
  background: url('../../../assets/img/svg/right.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  min-width: 36px;
  height: 110px;
  border: 1px solid #d5dfe4;
  border-radius: 5px;
}

::v-deep .navigation-prev {
  background: url('../../../assets/img/svg/right.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  min-width: 36px;
  height: 110px;
  border: 1px solid #d5dfe4;
  border-radius: 5px;
  transform: rotate(180deg);
}
</style>
