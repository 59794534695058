import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    totalPages: 0,
    showGenerateBloggerLinkModal: false,
    generateBloggerLinkModalMode: null,
  },
  mutations: {
    setTotalPages(state, value) {
      Vue.set(state, 'totalPages', value);
    },
    toggleGenerateBloggerLinkModal(state, { value, mode } = {}) {
      // mode in ['portfolio', 'customers']
      if (value === undefined) value = !state.showGenerateBloggerLinkModal;
      else state.showGenerateBloggerLinkModal = value;
      if (mode) state.generateBloggerLinkModalMode = mode || state.generateBloggerLinkModalMode;
    },
  },
};
