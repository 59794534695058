import Vue from 'vue';
import Vuex from 'vuex';
import search from '@mp/store/modules/search';
import ENUM from '@main/enums';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import reviewsList from './modules/reviews-list';
import reviewsModeration from './modules/reviews-moderation';
import notifications from './modules/notifications';
import mutualpr from './modules/mutualpr';
import popups from './modules/popups';
import payments from './modules/payments';
import promobot from './modules/promobot';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  strict: debug,
  modules: {
    reviewsList,
    reviewsModeration,
    notifications,
    mutualpr,
    mp_search: search,
    popups,
    payments,
    promobot,
  },
  state: {
    apiState: ENUM.INIT,
    vpClosed: true, // !!
    currentUser: null,
    userCart: null,
    showSignIn: false,
    footerLinks: [],
    bloggerOptions: null,
    backMode: null,
    tags: [],
    countries: [],
    cities: [],
    loading: {},
  },
  actions,
  mutations,
  getters,
});
