import apiService from '@main/api/index';
import { URL_PREFIX } from './index';

const SEARCH_MUTAL_PROFILES_URL = `${URL_PREFIX}/api/vp/search/`;
const SEARCH_ALL_MUTAL_PROFILES_URL = `${URL_PREFIX}/api/vp/searchAll/`;
const ONLINE_USER_COUNT_URL = `${URL_PREFIX}/api/vp/stat/online_count/`;
const SHOWCASE_PROFILES_URL = `${URL_PREFIX}/api/vp/showcase/`;
const CURRENT_SEARCH_URL = `${URL_PREFIX}/api/vp/current_search/`;
const MUTAL_PR_URL = `${URL_PREFIX}/api/vp/mutal_pr/`;
const PAST_PR_URL = `${URL_PREFIX}/api/vp/mutal_pr/?time=past`;
const TODAY_PR_URL = `${URL_PREFIX}/api/vp/mutal_pr/?time=today`;
const STATUS_URL = `${URL_PREFIX}/api/vp/status/`;
const RJECTED_URL = `${URL_PREFIX}/api/vp/mutal_pr/rejected/`;
const APPROVE_URL = `${URL_PREFIX}/api/vp/mutal_pr/approve/`;
const REQUESTED_URL = `${URL_PREFIX}/api/vp/mutal_pr/requested/`;
const CANCEL_MP_URL = `${URL_PREFIX}/api/vp/mutal_pr/cancel/`;
const HIDE_PR_URL = `${URL_PREFIX}/api/vp/mutal_pr/hide/`;
const RESTORE_URL = `${URL_PREFIX}/api/vp/mutal_pr/restore/`;
const GET_PROMO_IMAGE_URL = `${URL_PREFIX}/api/vp/profile/image/`;

/**
 * Получение пользователей с активированнм поиском
 */
export async function getOnlineCountUsers() {
  const response = await apiService.get(ONLINE_USER_COUNT_URL);
  return response.total_online || 0;
}

/**
 * Отображение профилей для главной страницы
 * @param {*} min
 * @param {*} max
 */
export async function getShowCaseProfiles(min = undefined, max = undefined) {
  console.log('getShowCaseProfiles', min, max);
  let filter = '?';
  if (min) filter += `coverage_min=${min}&`;
  if (max) filter += `coverage_max=${max}`;
  const response = await apiService.get(SHOWCASE_PROFILES_URL + filter);
  return response;
}

/**
 * Получение текущей поисковой выдачи
 */
export async function getCurrentSearch() {
  const response = await apiService.get(CURRENT_SEARCH_URL);
  return response;
}

/**
 * Обновление текущей поисковой выдачи
 */
export async function updateCurrentSearch(data) {
  const response = await apiService.put(CURRENT_SEARCH_URL, data);
  return response;
}

/**
 * Выключение поиска
 */
export async function offCurrentSearch() {
  const response = await apiService.delete(CURRENT_SEARCH_URL);
  return response;
}

/**
 * Активация поиска
 * @param {Режим} mode
 */
export async function createCurrentSearch(mode) {
  const response = await apiService.post(CURRENT_SEARCH_URL, { mode });
  return response;
}

/**
 * получение ВП на завтра
 */
export async function getMutalPr() {
  const response = await apiService.get(MUTAL_PR_URL);
  return response.results;
}

/**
 * Получение прошлых ВП
 */
export async function getPastMutalPr() {
  const response = await apiService.get(PAST_PR_URL);
  return response;
}

/**
 * Скрытие прошлых ВП
 */
export async function hidePastMutalPr(id) {
  const response = await apiService.post(HIDE_PR_URL, { id });
  return response;
}

/**
 * Получение ВП на сегодня
 */
export async function getTodayMutalPr() {
  const response = await apiService.get(TODAY_PR_URL);
  return response;
}

/**
 * Удаление запроса на ВП
 * @param {} profileId
 */
export async function removeRequest(profileId) {
  const response = await apiService.delete(MUTAL_PR_URL, { profile_id: profileId });
  return response;
}

/**
 * Получение статуса всей поисковой выдачи
 */
export async function getStatus() {
  const response = await apiService.get(STATUS_URL);
  return response;
}

/**
 * Отклонение заявки
 * @param {*} accountId
 */
export async function rejected(accountId) {
  const response = await apiService.post(RJECTED_URL, { account_id: accountId });
  return response;
}

/**
 * Восстановление заявки
 * @param {*} accountId
 */
export async function restore(accountId) {
  const response = await apiService.post(RESTORE_URL, { account_id: accountId });
  return response;
}

/**
 * Принятие заявки
 * @param {*} accountId
 */
export async function approve(accountId) {
  const response = await apiService.post(APPROVE_URL, { account_id: accountId });
  return response;
}

/**
 *
 * @param {*} accountId
 */
export async function requested(accountId) {
  const response = await apiService.post(REQUESTED_URL, { account_id: accountId });
  return response;
}

/**
 * Отмена сформированного на завтра ВП
 * @param {*} accountId
 */
export async function cancelMp(accountId) {
  const response = await apiService.post(CANCEL_MP_URL, { account_id: accountId });
  return response;
}

/**
 * Получение изображения для поиска в сторис
 */
export async function getPromoImage() {
  const response = await apiService.get(GET_PROMO_IMAGE_URL);
  return response.url;
}
