import enums from '@main/enums';
import store from '../store/index';
import { router } from '../router';

/* eslint-disable func-names */

export default {
  requiresAuth(func, next) {
    if (!store.state.currentUser) {
      router.push({ query: { next } });
      store.commit('toggleSignIn', true);
      return;
    }
    return func();
  },
  requiresSub(func, next) {
    console.log('sub');
    if (!store.state.currentUser) {
      router.push({ query: { next } });
      store.commit('toggleSignIn', true);
      return;
    }
    if (!store.state.currentUser.services_subscriber) {
      window.location.href = 'https://sub.easyprbot.com';
      return;
    }
    return func();
  },
  requiresBloggerProfile(func, bypass) {
    console.log('prf');
    if (
      store.state.currentUser &&
      (!store.state.currentUser.blogger_profile ||
        !store.state.currentUser.blogger_profile.filled) &&
      !bypass
    ) {
      router.push({ name: 'fill_statistics' });
      store.commit('notifications/showMessage', {
        title: store.state.currentUser.blogger_profile
          ? 'Необходимо заполнить карточку блогера до конца!'
          : 'Необходимо заполнить карточку блогера!',
        icon: 2,
      });
      return;
    }
    return func();
  },
  requiresBloggerProfileModerated(func) {
    console.log('mod');
    const status = Number(store.state.currentUser.blogger_profile.moderation_status);
    if (enums.bloggers.moderation_statuses.groups.onModeration.includes(status)) {
      store.commit('notifications/showMessage', {
        title: 'Ваша карточка еще на модерации - попробуйте позже!',
        icon: 2,
      });
    } else if (enums.bloggers.moderation_statuses.groups.unsafe.includes(status)) {
      router.push({ name: 'fill_statistics' });
      store.commit('notifications/showMessage', {
        title: 'Ваша карточка отклонена - исправьте ошибки и отправьте заново!',
        icon: 2,
      });
    } else {
      return func();
    }
  },
  async triggersLoading(func, attr, bypass) {
    if (!bypass) {
      store.commit('startLoading', attr);
    }
    let result;
    try {
      result = await func();
    } catch (e) {
      console.log(e);
    }
    if (!bypass) {
      store.commit('stopLoading', attr);
    }
    return result;
  },
};
