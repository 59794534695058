<template>
  <MultiStepModal
    :steps="steps"
    :currentStep="currentStep"
    wrapperTypeClass="change-phone-popup"
    title="Изменение пароля"
    v-if="changePasswordModal.open"
    :open="changePasswordModal.open"
    @close="() => toggleChangePasswordModal({ open: false, context: { phone: null } })"
  >
    <template v-slot:1>
      <form class="needs-validation" novalidate>
        <div class="sms-top">
          Введите ваш номер телефона. <br />
          Мы отправим на него код
        </div>
        <div class="form-group">
          <b-form-input
            @input="
              (val) => {
                enteredPhone = val.replace(/[^\w]/g, '');
              }
            "
            placeholder="В формате, 79999999999"
            :value="enteredPhone"
            class="w-100 popup__input"
          ></b-form-input>
          <div :v-if="!enteredPhone" class="code-link__caption open">
            <div>Номер телефона не может содержать пробелов и букв</div>
          </div>
          <div v-if="codeError" style="font-size: 12px; margin-top: 5px" class="red">
            {{ codeError }}
          </div>
        </div>
      </form>
    </template>
    <template v-slot:2>
      <form class="needs-validation" novalidate>
        <div class="sms-top">Код из SMS</div>
        <div class="form-group">
          <div class="pass-label">
            <label for="code">
              Мы отправили вам код подтверждения на номер <span v-phone-number="phoneValue"></span>
            </label>
          </div>
          <input
            type="text"
            class="form-control"
            id="code1"
            v-model="code"
            placeholder="Код"
            required
          />
          <a @click="resendCode" :class="['code-link', tryTime > 0 ? 'code-link-open' : '']">
            Отправить код повторно
          </a>
          <p v-if="codeError" style="margin-top: 5px; font-size: 12px" class="red">
            {{ codeError }}
          </p>
          <div v-if="!!tries" class="code-link__caption">
            <div>
              Попытка №{{ tries }} <br />
              Запросить код повторно можно через {{ tryTime }} секунд
            </div>
            <a>
              <span class="info left bottom">
                Не приходит SMS
                <div class="info__txt">
                  Возможные причины: <br /><br />
                  1. Вы не изменили номер мобильного телефона на сайте на новый и SMS было
                  отправлено на старый номер.<br /><br />
                  2. Проблемы могут быть со стороны сотового оператора. Обратитесь в их службу
                  поддержки.
                </div>
              </span>
            </a>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:3>
      <form class="needs-validation" novalidate>
        <div class="form-group">
          <div class="pass-label">
            <label for="pass2">Введите новый пароль</label>
          </div>
          <input
            :class="{ error: $v.password.$anyError }"
            type="password"
            v-model="password"
            class="form-control"
            id="pass4"
            minlength="6"
            required
          />
          <div class="login__content-capt">
            От 6 символов. Минимум по 1 букве верхнего и нижнего регистра
          </div>
        </div>
        <div class="form-group">
          <div class="pass-label">
            <label for="pass3">Подтвердите пароль</label>
          </div>
          <input
            :class="{ error: $v.repeatPassword.$anyError }"
            v-model="repeatPassword"
            type="password"
            class="form-control"
            id="pass5"
            minlength="6"
            required
          />
        </div>

        <div class="error-form">Заполните поля отмеченные красным</div>
        <div class="error-form pass">Пароли не совпадают</div>
      </form>
    </template>
    <template v-slot:footer>
      <div class="login__bottom">
        <b-button
          @click="
            () => {
              if (currentStep > 1) {
                currentStep -= 1;
              } else {
                toggleChangePasswordModal({ open: false, context: { phone: null } });
              }
            }
          "
          variant="outline-default"
          class="to-login"
          >Назад</b-button
        >
        <b-button @click="forward" variant="primary" class="next-tap">{{
          currentStep === 3 ? 'Сохранить' : 'Далее'
        }}</b-button>
      </div>
    </template>
  </MultiStepModal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import auth from '@main/api/auth';
import MultiStepModal from '@main/components/reusable/modals/MultiStepModal.vue';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
  components: {
    MultiStepModal,
  },
  data: () => ({
    disablePhoneBack: false,
    enteredPhone: null,
    steps: 3,
    password: '',
    repeatPassword: '',
    code: '',
    codeId: '',
    codeError: '',
    tries: 0,
    tryTime: 0,
    tryInterval: null,
    currentStep: 1,
  }),
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('popups', ['changePasswordModal']),
    phoneValue() {
      let phoneValue;
      if (this.changePasswordModal.context.phone) {
        if (this.enteredPhone && this.enteredPhone.length > 8) {
          phoneValue = this.enteredPhone;
        } else {
          phoneValue = this.changePasswordModal.context.phone;
        }
      } else {
        phoneValue = this.enteredPhone;
      }
      return phoneValue;
    },
    formattedPhone() {
      const str = String(this.phoneValue).replace('+', '');
      return `+7 (${str.slice(1, 4)}) xxx-xx-${str.slice(9, 11)}`;
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('notifications', ['showMessage']),
    ...mapMutations('popups', ['toggleChangePasswordModal']),
    async sendCode(data, disableMovement) {
      try {
        const resp = await auth.sendCode(data, { reset_password: 1 });
        if (!resp.success) {
          this.codeError = resp.error;
        }
        this.codeId = resp.code_id;
        return resp;
      } catch (e) {
        this.codeError = e.response.error;
      }
    },
    async resendCode() {
      if (!this.tryTime) {
        this.tries += 1;
        await this.sendCode();
        this.tryTime = 60;
        this.setTryInterval();
      }
    },
    async checkCode() {
      try {
        const resp = await auth.checkCode(this.codeId, this.code);
        if (resp.success) {
          this.goRight();
        } else if (resp.error) {
          this.codeError = resp.error;
        } else {
          this.codeError = 'Неверный код';
        }
      } catch (e) {
        this.showMessage({
          title:
            'Возникла ошибка при проверке кода! Попробуйте позже или обратитесь в тех. поддержку',
        });
      }
    },
    async setNewPassword() {
      if (this.password && this.codeId) {
        try {
          const resp = await auth.changePassword(
            this.password,
            this.codeId,
            String(Number(this.phoneValue))
          );
          if (resp.success) {
            this.loadUser();
            this.showMessage({
              id: 500,
              type: 'basic',
              title: 'Пароль успешно изменен',
              icon: 1,
            });
            this.$emit('close');
          }
        } catch (e) {
          this.showMessage({
            title:
              'Ошибка при изменении пароля! Попробуйте позднее или обратитесь в тех. поддержку!',
          });
        }
      }
    },
    setTryInterval() {
      this.tryTime = 60;
      this.tryInterval = setInterval(() => {
        if (this.tryTime <= 0) {
          this.tryInterval = 0;
          clearInterval(this.tryInterval);
        } else {
          this.tryTime -= 1;
        }
      }, 1000);
    },
    async forward() {
      switch (this.currentStep) {
        case 1:
          if (this.enteredPhone) {
            const res = await this.sendCode({ phone: this.enteredPhone });
            if (res && res.success) {
              this.goRight();
            }
          }
          break;
        case 2:
          await this.checkCode();
          break;
        case 3:
          await this.setNewPassword();
          this.toggleChangePasswordModal({ open: false, context: { phone: null } });
          this.toggleSignIn(true);
          break;
        default:
      }
    },
    goRight() {
      if (this.currentStep < this.steps) {
        this.currentStep += 1;
      }
    },
  },
  watch: {
    enteredPhone(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="css" scoped></style>
