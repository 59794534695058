import apiService from './index';

export default {
  referalPrize: {
    async list(params) {
      const result = apiService.get('/api/referal/referal_prize/', { params });
      return result;
    },
  },
  code: {
    async my() {
      const result = apiService.get('/api/referal/code/my/');
      return result;
    },
    async setRefererCode(code) {
      const result = apiService.post('/api/users/set_referer_code/', { code });
      return result;
    },
  },
};
