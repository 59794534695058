<template>
  <Modal
    v-bind="{ ...$props, ...$attrs }"
    :title="`${instagramVerificationModal.context.prAccount ? 'PR-' : 'Основной '}аккаунт`"
    :show="true"
    contentClass="overflow-auto"
    @hide="
      () =>
        toggleInstagramVerificationModal({
          open: false,
          context: { prAccount: false },
        })
    "
    ><div class="p-4">
      <InstagramVerificationWindow v-bind="instagramVerificationModal.context" />
    </div>
  </Modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import InstagramVerificationWindow from '@main/components/reusable/windows/InstagramVerificationWindow.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  components: {
    Modal,
    InstagramVerificationWindow,
  },
  methods: {
    ...mapMutations('popups', ['toggleInstagramVerificationModal']),
  },
  computed: {
    ...mapState('popups', ['instagramVerificationModal']),
  },
};
</script>

<style scoped lang="scss">
::v-deep .verification {
  border: none !important;
  padding: 0 4px !important;
}
::v-deep .verificatepracc {
  margin-top: 10px;
}
</style>
