<template>
  <modal
    :show="true"
    @hide="hide"
    :hideAnim="true"
    title="Купить проверки блогеров"
    wrapClass="modal-lg"
    contentClass="overflow-auto"
  >
    <div class="px-3 mx-3 mt-3 fs-16 d-flex flex-row align-items-center avail-title">
      <p>Сейчас вам доступно проверок блогеров: {{ currentUser.profile.energy }}</p>
      <img class="ml-1" src="@main/assets/img/svg/energy.svg" alt="" />
    </div>
    <div
      class="px-3 pb-3 pt-1 d-flex flex-row justify-content-between flex-wrap flex-md-nowrap mb-5"
    >
      <template v-if="prices">
        <div
          :key="`${price.id}awidnaw892dwoaidjo`"
          class="position-relative m-3"
          v-for="price in prices"
        >
          <b-button
            @click="() => createPayment(price.id)"
            variant="outline-custom"
            class="text-center yellow-price__block p-3"
          >
            <p class="text-center mx-auto mt-2">{{ price.name }}</p>
            <div class="w-100 h-50 mt-3" :class="`energy_price__${price.code}`"></div>
            <h1 style="white-space: nowrap !important" class="text-center mx-auto mt-2">
              {{ (price.amount / 100).toLocaleString().replaceAll(',', ' ') }}
              ₽
            </h1>
            <p style="opacity: 0.5; color: #192229" v-if="price.quantity > 1" class="mt-1">
              {{ price.amount / 100 / price.quantity }} ₽ за 1 шт.
            </p>
          </b-button>
          <div
            v-if="price.short_info"
            class="py-2 yellow-price__block--bottom-badge text-center"
            style="margin-top: -20px !important"
          >
            <p class="mt-3 font-weight-bold" style="color: white">{{ price.short_info }}</p>
          </div>
        </div>
      </template>
      <Loader v-else class="p-5 mx-auto mt-4" />
    </div>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';
import Loader from '@main/components/Loader.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import payments from '@main/api/payments';

export default {
  components: {
    Modal,
    Loader,
  },
  data: () => ({
    prices: null,
  }),
  computed: {
    ...mapState(['currentUser']),
  },
  methods: {
    ...mapMutations('payments', ['toggleEnergyPriceModal']),
    async createPayment(payId) {
      const response = await payments.energy.createPayment(payId);
      if (response.url) {
        window.location = response[0].PaymentURL;
      }
      this.hide();
    },
    hide() {
      this.toggleEnergyPriceModal(false);
    },
  },
  async mounted() {
    const res = await payments.energy.getPrice();
    this.prices = res.results;
  },
};
</script>

<style lang="css" scoped>
.overline {
  text-decoration: line-through;
  margin-bottom: 5px;
  color: gray;
}
.discount {
  margin-left: 5px;
}
@media (max-width: 330px) {
  .popup-new-service__item-total {
    font-size: 19px !important;
  }
  .avail-title {
    font-size: 1.1rem !important;
  }
}
</style>
