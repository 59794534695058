import axios from 'axios';
import * as rax from 'retry-axios';

const cookieToken = window.$cookies.get('jwt_access');
const storageToken = localStorage.getItem('token');
const csrfToken = window.$cookies.get('csrftoken');

const headers = {
  contentType: 'application/json',
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
  withCredentials: true,
};

if (cookieToken) {
  headers.Authorization = `Bearer ${cookieToken}`;
} else if (storageToken) {
  headers.Authorization = `Bearer ${storageToken}`;
}
if (csrfToken) {
  headers['X-CSRFToken'] = csrfToken;
}

const apiService = axios.create({
  timeout: 15000,
  headers,
});

rax.attach(apiService);

apiService.interceptors.response.use(
  (response) => {
    if (!response || typeof response === 'undefined') {
      return { success: false };
    }
    if ('data' in response) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (!error.response) {
      return;
    }
    if (error.response.status === 401) {
      if (error.response.data.code === 'token_not_valid') {
        console.log('TOKEN ERROR - RELOADING');
        window.$cookies.remove('jwt_access');
        localStorage.removeItem('token');
        window.location.reload();
      }
      throw error;
    }
    throw error;
  }
);

export default apiService;
