<template>
  <Multiselect
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    :options="cities"
    placeholder="Выберите город"
    track-by="id"
    label="name"
    :searchable="true"
    :options-limit="300"
    :loading="isLoading"
    select-label=""
    deselect-label=""
    :internal-search="false"
    @search-change="asyncFind"
    @click="asyncFind()"
  >
    <span slot="noResult">Город не найден</span>
    <template #tagPlaceholder>Выберите город</template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import info from '@main/api/info';

export default {
  data: () => ({
    cities: [],
    isLoading: false,
  }),
  components: {
    Multiselect,
  },
  methods: {
    asyncFind(query) {
      this.isLoading = true;
      info.getCities({ name: query }).then((res) => {
        this.cities = res;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.asyncFind();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect__tags {
  border: 1px solid #ced4da !important;
}
::v-deep .multiselect__placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

::v-deep .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.percentage__input--percent {
  height: 33.5px !important;
}
.custom-control {
  padding-left: 2.09rem !important;
}

::v-deep .multiselect__content {
  max-width: 100%;
}

::v-deep .multiselect__tag {
  background: #3897f0 !important;
  margin: 0px !important;
}

::v-deep .multiselect__option--highlight {
  background: #3897f0 !important;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 0px !important;
  padding: 0px !important;
}

::v-deep .multiselect,
::v-deep .multiselect__input,
::v-deep .multiselect__single {
  font-size: 14px !important;
}

::v-deep .multiselect__single {
  padding-left: 5px !important;
  margin-bottom: 0px !important;
  margin-top: 2px !important;
  line-height: 21px !important;
}

::v-deep .multiselect__input {
  margin-bottom: 4px !important;
}

::v-deep .multiselect__select {
  height: 33px !important;
  top: 0px !important;
}

::v-deep .multiselect__tag-icon:after {
  color: #fff;
}

::v-deep .multiselect__tags {
  min-height: 33.5px !important;
  padding: 4px 36px 0px 8px !important;
}

::v-deep .multiselect {
  min-height: 33.5px !important;
}
::v-deep .multiselect__spinner::after {
  border-top-color: #3897f0;
}
::v-deep .multiselect__spinner::before {
  border-top-color: #3897f0;
}
::v-deep .multiselect__spinner {
  width: 26px !important;
  height: 30px !important;
  margin-right: 2px !important;
}
</style>
