import apiService from '@main/api/index';
import { URL_PREFIX } from './index';

const UPDATE_PRICE_URL = `${URL_PREFIX}/api/payments/updates_price/`;
const PREMIUM_PRICE_URL = `${URL_PREFIX}/api/payments/premium_price/`;
const PREMIUM_OFFERS_URL = `${URL_PREFIX}/api/payments/premium_offers/`;
const PAYMENT_UPDATE_URL = `${URL_PREFIX}/api/vp/payment/`;
const CREATE_PREMIUM_PAYMENT_URL = `${URL_PREFIX}/api/payments/premium/create/`;
const CREATE_UPDATE_PAYMENT_URL = `${URL_PREFIX}/api/payments/updates/create/`;

/**
 * Премиум выдача для тестов
 */
export async function fakePayment() {
  const response = await apiService.post(PAYMENT_UPDATE_URL);
  return response;
}

/**
 * Создание платежа за премиум аккаунт
 * @param {number} priceId Id тарифа в системе
 * @param {string} redirectParam Редирект после платежа
 */
export async function CreatePremiumPayment(priceId) {
  const response = await apiService.post(CREATE_PREMIUM_PAYMENT_URL, {
    price_id: priceId,
  });
  return response;
}

/**
 * Создание платежа за обновление выдачи
 * @param {number} paymentId id тарифа в системе
 * @param {string} redirectParam Редирект после платежа
 */
export async function CreateUpdatePayment(paymentId, redirectParam) {
  const response = await apiService.post(CREATE_UPDATE_PAYMENT_URL, {
    price_id: paymentId,
    redirect: redirectParam,
  });
  return response;
}

/**
 * Получение цен на премиум аккаунты
 */
export async function getPremiumPrice() {
  const response = await apiService.get(PREMIUM_PRICE_URL);
  return response.results;
}

export async function getPremiumPriceOffers() {
  const response = await apiService.get(PREMIUM_OFFERS_URL);
  return response.results;
}

/**
 * Получение цен на обновление выдачи
 */
export async function getUpdatePrice() {
  const response = await apiService.get(UPDATE_PRICE_URL);
  return response;
}
