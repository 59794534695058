<template>
  <modal
    :show="show"
    @hide="$emit('hide')"
    :title="`Скриншоты статистики блогера ${account.blogger_profile.instaname}`"
    wrapClass="popup-stats__wrap"
    contentClass="popup-stats__content popup-stats__content-flex"
  >
    <div
      class="popup-stats__item"
      v-if="account.blogger_profile.coverage_screen.medium_square_crop"
    >
      <div class="popup-stats__flex">
        <span class="popup-stats__info">Охваты сторис</span>
        <!--span class="popup-stats__date">на 29.07.19</span-->
      </div>
      <img
        :src="account.blogger_profile.coverage_screen.medium_square_crop"
        class="popup-stats__img"
        alt="Нет изоражения"
      />
    </div>
    <div
      class="popup-stats__item"
      v-if="account.blogger_profile.main_country.screen.medium_square_crop"
    >
      <div class="popup-stats__flex">
        <span class="popup-stats__info">Преобладающая страна</span>
        <!--span class="popup-stats__date">на 29.07.19</span-->
      </div>
      <img
        :src="account.blogger_profile.main_country.screen.medium_square_crop"
        class="popup-stats__img"
        alt="Нет изображения"
      />
    </div>

    <div
      class="popup-stats__item"
      v-if="account.blogger_profile.main_audience.screen.medium_square_crop"
    >
      <div class="popup-stats__flex">
        <span class="popup-stats__info">Аудитория</span>
        <!--span class="popup-stats__date">на 29.07.19</span-->
      </div>
      <img
        alt="Нет изображения"
        :src="account.blogger_profile.main_audience.screen.medium_square_crop"
        class="popup-stats__img"
      />
    </div>
    <div
      class="popup-stats__item"
      v-if="account.blogger_profile.women_percent_screen.medium_square_crop"
    >
      <div class="popup-stats__flex">
        <span class="popup-stats__info">Процент женщин</span>
        <!--span class="popup-stats__date">на 29.07.19</span-->
      </div>
      <img
        alt="Нет изображения"
        :src="account.blogger_profile.women_percent_screen.medium_square_crop"
        class="popup-stats__img"
      />
    </div>
    <div
      class="popup-stats__item"
      v-if="account.blogger_profile.main_city.screen.medium_square_crop"
    >
      <div class="popup-stats__flex">
        <span class="popup-stats__info">Преобладающий город</span>
        <!--span class="popup-stats__date">на 29.07.19</span-->
      </div>
      <img
        alt="Нет изображения"
        :src="account.blogger_profile.main_city.screen.medium_square_crop"
        class="popup-stats__img"
      />
    </div>
    <div
      class="popup-stats__item"
      v-if="account.blogger_profile.posts_coverage_screen.medium_square_crop"
    >
      <div class="popup-stats__flex">
        <span class="popup-stats__info">Охваты постов</span>
        <!--span class="popup-stats__date">на 29.07.19</span-->
      </div>
      <img
        alt="Нет изображения"
        :src="account.blogger_profile.posts_coverage_screen.medium_square_crop"
        class="popup-stats__img"
      />
    </div>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
    account: Object,
  },
  components: {
    Modal,
  },
};
</script>

<style scoped>
.footer {
  width: auto !important;
}
#popupInstruction {
  margin-top: 3em;
}
.popup-stats__container {
  overflow: scroll;
}
.popup-stats__img::before {
  padding: 10px;
}
</style>
