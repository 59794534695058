import Vue from 'vue';
import 'vue2-animate/dist/vue2-animate.min.css';
import Vuelidate from 'vuelidate';
import Clipboard from 'v-clipboard';
import VueClipboard from 'vue-clipboard2';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueScrollactive from 'vue-scrollactive';
import VueCookies from 'vue-cookies';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueCarousel from 'vue-carousel';
import parsePhoneNumber from 'libphonenumber-js';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { isMobile } from 'mobile-device-detect';
import lineClamp from 'vue-line-clamp';
import literal from '@main/func/literal';
import App from './App.vue';
import store from './store';
import './filters';
import './styles/normalize.scss';
import './styles/main.scss';
import './styles/statistics.scss';
import './styles/bootstrap.scss';
import './styles/style.css';
import './styles/mutualpr.scss';
import './styles/header.scss';
import './styles/dynamic_icons.scss';
import './styles/blogger_checker.scss';
import 'swiper/swiper-bundle.css';

import { router } from './router';

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));

Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueClipboard);
Vue.use(lineClamp, { importCss: true });
Vue.use(Clipboard);
Vue.use(VueScrollactive);
Vue.use(VueLodash, { lodash });
Vue.use(VueCarousel);
Vue.use(VueAwesomeSwiper, {
  keyboard: {
    enabled: true,
  },
  mousewheel: {
    enabled: true,
  },
  breakpoints: {
    // when window width is >= 480px
    480: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 5,
      spaceBetween: 15,
    },
    750: {
      slidesPerView: 5,
      spaceBetween: 15,
    },
    850: {
      slidesPerView: 6,
      spaceBetween: 15,
    },
    950: {
      slidesPerView: 6,
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: 7,
      spaceBetween: 15,
    },
    1500: {
      slidesPerView: 8,
      spaceBetween: 40,
    },
  },
});

Vue.$cookies.config('16d');

Object.defineProperty(Vue.prototype, '$bus', {
  get() {
    return this.$root.bus;
  },
});

Vue.prototype.isMobile = isMobile;
Vue.prototype.window = window;
Vue.prototype.document = document;
Vue.prototype.literal = literal;

Vue.directive('hideon', {
  // Когда привязанный элемент вставлен в DOM...
  bind(el, binding) {
    el.addEventListener('click', () => {
      document.getElementById(binding.value).remove();
      localStorage.setItem(binding.value, true);
    });
  },
  inserted(el, binding) {
    const hide = localStorage.getItem(binding.value);
    if (hide) {
      document.getElementById(binding.value).remove();
    }
  },
});

Vue.directive('savedata', {
  bind(el, binding) {
    el.addEventListener('input', (event) => {
      localStorage.setItem(el.id, event.target.value);
    });
  },
  inserted(el) {
    if (localStorage.getItem(el.id)) {
      Vue.set(el, 'value', localStorage.getItem(el.id));
    }
  },
});

Vue.directive('hover-to', {
  bind(el, binding) {
    el.addEventListener('click', (e) => {
      document
        .getElementById(binding.value)
        .scrollIntoView({ behavior: 'smooth', alignToTop: true });
    });
  },
});

Vue.directive('reset-scroll', {
  bind(el, binding) {
    el.addEventListener(binding.value, () => {
      window.scrollTo(0, 0);
    });
  },
});

Vue.directive('symbolic-filter', {
  bind(el, binding) {
    el.addEventListener('keyup', (e) => {
      e.target.value = e.target.value.replaceAll(binding.value, '');
    });
  },
});

Vue.directive('phone-number', {
  bind(el, binding) {
    let formatedNumber = parsePhoneNumber(`+${String(binding.value)}`).formatNational();
    if (!formatedNumber) {
      return binding.value;
    }
    if (formatedNumber.length === 17 && formatedNumber.startsWith(8)) {
      formatedNumber = formatedNumber.replace('8', '+7');
    }
    el.innerHTML = formatedNumber;
  },
});

new Vue({
  data: {
    bus: new Vue({}),
  },
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
