<template>
  <div
    id="filter-modal"
    :is="isMobile ? SideModal : Div"
    class="reviews-filter-wrapper w-100 mr-xl-3"
    v-model="filterOpen"
    title="Фильтры"
    :ok-title="'Поиск'"
    :cancel-title="'Сброс'"
    @ok="search"
    @cancel="resetFilterParams"
    @click.self="
      () => {
        $emit('reset');
        filterOpen = false;
      }
    "
  >
    <div class="reviews-filter-backdround d-block d-lg-block"></div>
    <form
      @keydown.enter="search"
      @submit.prevent="search"
      class="review-wrapper container rectangle-block bg-lg-white rounded-0 rounded-md mr-2 ml-0 px-0 pt-0 pt-lg-3 pt-lg-2 h-100"
      :class="{ 'review-wrapper--open': filterOpen, 'w-100': !isMobile }"
      data-scroll-speed="70"
      v-if="internalFilterParams"
    >
      <!-- <template v-if="isMobile">
        <p class="border-bottom px-4 py-3" style="font-size: 20px">Фильтры</p>
        <div
          class="border-bottom px-4 py-4 d-flex align-items-center justify-content-between"
          style="font-size: 16px"
        >
          <div>Показывать по</div>
          <div class="select-wrapper" style="width: 58px">
            <select class="select" v-model="internalFilterParams.page_size">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>  
            </select>
          </div>
        </div>
        <div class="ml-3 py-3">
          <b-btn variant="outline-secondary" class="mr-2" @click="resetFilterParams()"
            >Сбросить</b-btn
          >
          <b-btn @click="search" variant="yellow">Найти</b-btn>
        </div>
      </template> -->
      <div class="row w-100 px-3 mb-4 ml-0 mr-0">
        <div class="col-lg-3 col-12 pl-0">
          <b-form-group
            style="max-width: 83%"
            label-class="ws-nowrap"
            label="Брали рекламу у блогера"
          >
            <b-form-input
              placeholder="Введите ник блогера"
              v-model="internalFilterParams.executor"
            />
          </b-form-group>
        </div>
        <div class="col-lg-3 col-12 pl-0">
          <b-form-group label-class="ws-nowrap" label="Цена за рекламу в ₽" style="max-width: 83%">
            <div class="row">
              <div class="col pr-lg-1 pr-lg-1">
                <b-form-input
                  type="number"
                  placeholder="от 450"
                  v-model="internalFilterParams.price_min"
                />
              </div>
              <div class="col pl-1">
                <b-form-input
                  type="number"
                  placeholder="до 99000"
                  v-model="internalFilterParams.price_max"
                />
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-3 col-12 pl-0">
          <b-form-group label-class="ws-nowrap" label="Приход подписчиков" style="max-width: 83%">
            <div class="row">
              <div class="col pr-1">
                <b-form-input
                  type="number"
                  placeholder="от 20"
                  v-model="internalFilterParams.arrival_min"
                />
              </div>
              <div class="col pl-1">
                <b-form-input
                  type="number"
                  placeholder="до 56 000"
                  v-model="internalFilterParams.arrival_max"
                />
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-3 col-12 pl-0">
          <b-form-group>
            <div
              style="margin-top: 4px"
              class="option"
              :class="{
                'option--active': internalFilterParams.price_meta.indexOf('together') !== -1,
              }"
              @click="togglePriceMeta('together')"
            >
              <div class="option__select"></div>
              <span class="option__title">Взаимопиар</span>
            </div>
            <div
              class="option"
              :class="{
                'option--active': internalFilterParams.price_meta.indexOf('barter') !== -1,
              }"
              @click="togglePriceMeta('barter')"
            >
              <div class="option__select"></div>
              <span class="option__title">Бартер</span>
            </div>
            <div
              class="option"
              :class="{ 'option--active': disclosedReview }"
              @click="disclosedReview = !disclosedReview"
            >
              <div class="option__select"></div>
              <span class="option__title">Раскрывать все отзывы</span>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row w-100 px-3 mb-4 ml-0 mr-0">
        <div class="col-lg-3 col-12 pl-0">
          <b-form-group
            label-class="ws-nowrap"
            style="max-width: 83%"
            label="Темы заказчиков рекламы"
          >
            <multiselect
              :options="tags"
              placeholder="Выберите темы"
              select-label=""
              deselectLabel=""
              selectedLabel="Выбрано"
              label="name"
              track-by="id"
              :searchable="false"
              :multiple="true"
              :value="tags.filter((val) => internalFilterParams.tags.includes(val.id))"
              @input="
                (value) => {
                  internalFilterParams.tags = value.map((val) => val.id);
                }
              "
            >
              <span slot="noResult">Тема не найдена</span>
              <template #tagPlaceholder>Выберите тему</template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-lg-3 col-12 pl-0">
          <b-form-group
            label-class="ws-nowrap"
            label="Цена за подписчика в ₽"
            style="max-width: 83%"
          >
            <div class="row">
              <div class="col pr-1">
                <b-form-input
                  type="number"
                  placeholder="от 1"
                  v-model="internalFilterParams.price_per_one_min"
                />
              </div>
              <div class="col pl-1">
                <b-form-input
                  type="number"
                  placeholder="до 400"
                  v-model="internalFilterParams.price_per_one_max"
                />
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-3 col-12 pl-0">
          <b-form-group label-class="ws-nowrap" label="Формат рекламы" style="max-width: 83%">
            <multiselect
              :options="adTypes"
              placeholder="Выберите формат"
              select-label=""
              deselectLabel=""
              selectedLabel="Выбрано"
              label="name"
              :searchable="false"
              track-by="id"
              :value="adTypes.filter((tp) => tp.id === internalFilterParams.ad_type)[0]"
              @input="
                (val) => {
                  internalFilterParams.ad_type = val.id;
                }
              "
            >
              <template slot="tagPlaceholder">Выберите формат</template>
            </multiselect>
          </b-form-group>
        </div>
        <div
          class="col-lg-3 col-12 pl-0 d-flex align-items-end justify-content-between mb-3"
          v-if="!isMobile"
        >
          <div class="row w-100">
            <div class="col-6 pr-1">
              <b-button variant="yellow" @click="search" class="w-100">Найти</b-button>
            </div>
            <div class="col-6 pl-1">
              <b-button
                variant="outline-secondary"
                class="w-100"
                @keyup.ctrl.88="resetFilterParams()"
                @click="resetFilterParams()"
                >Сбросить</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div
        :style="{
          'border-bottom': extraFilters ? '1px solid #D5DFE4' : '',
          'margin-bottom': extraFilters ? '35px !important' : '0px !important',
        }"
        class="filters__next--text row w-100 px-3 mb-4 ml-0 mr-0"
      >
        <div class="col-12 pl-0">
          <span> Фильтры по охватам, странам, городам, полу и возрасту аудитории </span>
          <span @click="extraFilters = !extraFilters" class="show--filters">{{
            extraFilters ? 'Скрыть ↑' : 'Показать ↓'
          }}</span>
        </div>
      </div>
      <b-collapse id="collapse-1" :visible="extraFilters || isMobile">
        <div style="position: relative">
          <div v-if="!currentUser || !currentUser.services_subscriber" class="pay__filters">
            <p class="pay__filters--text">Разблокируются в полной версии отзывов</p>
            <b-button
              @click="toggleSubscriptionPriceModal(true)"
              target="_blank"
              class="pay--filters_btn"
              variant="warning"
              >Купить доступ от 1990 ₽ в месяц</b-button
            >
          </div>
          <div
            style="background: white; opacity: 0.5"
            v-if="!currentUser || !currentUser.services_subscriber"
            class="filter__unsub--cover"
          ></div>
          <div class="d-flex flex-wrap w-100 px-3 mr-0 ml-0">
            <div class="col-lg-3 col-12 pl-0">
              <b-form-group label-class="ws-nowrap" label="Cтрана аудитории">
                <div class="d-flex flex-row" style="max-width: 83%">
                  <multiselect
                    :options="countries"
                    placeholder="Выберите страну"
                    select-label=""
                    deselectLabel=""
                    selectedLabel="Выбрано"
                    label="name"
                    track-by="id"
                    :searchable="true"
                    :value="countries.filter((val) => val.id === internalFilterParams.country)"
                    @input="
                      (val) => {
                        internalFilterParams.country = val.id;
                      }
                    "
                  >
                    <span slot="noResult">Страна не найдена</span>
                    <template #tagPlaceholder>Выберите страну</template>
                  </multiselect>

                  <b-form-input
                    class="pcnt--inp"
                    type="number"
                    placeholder="от  %"
                    v-model="internalFilterParams.country_percent"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-3 col-12 pl-0">
              <b-form-group label-class="ws-nowrap" label="Город аудитории">
                <div class="d-flex flex-row" style="max-width: 83%">
                  <CitySelect
                    v-model="internalFilterParams.city"
                    deselectLabel=""
                    selectedLabel=""
                  />
                  <!--multiselect
                    placeholder="Выберите город"
                    select-label=""
                    deselectLabel=""
                    selectedLabel="Выбрано"
                    label="name"
                    track-by="id"
                    :searchable="true"
                    :value="cities.filter((val) => val.id === internalFilterParams.city)"
                    @input="
                      (val) => {
                        internalFilterParams.city = val.id;
                      }
                    "
                  >
                    <span slot="noResult">Город не найден</span>
                    <template #tagPlaceholder>Выберите город</template>
                  </!--multiselect-->

                  <b-form-input
                    class="pcnt--inp"
                    type="number"
                    placeholder="от   %"
                    v-model="internalFilterParams.city_percent"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-3 col-12 pl-0">
              <b-form-group label-class="ws-nowrap" label="Возраст аудитории">
                <div class="d-flex flex-row" style="max-width: 83%">
                  <multiselect
                    :options="options.audiences.child.children.age_group.choices"
                    placeholder="Выберите возраст"
                    select-label=""
                    deselectLabel=""
                    selectedLabel="Выбрано"
                    label="display_name"
                    track-by="value"
                    :searchable="false"
                    :value="
                      options.audiences.child.children.age_group.choices.filter(
                        (val) => val.value === internalFilterParams.audience
                      )
                    "
                    @input="
                      (val) => {
                        internalFilterParams.audience = val.value;
                      }
                    "
                  >
                    <span slot="noResult">Возраст не найден</span>
                    <template #tagPlaceholder>Выберите возраст</template>
                  </multiselect>

                  <b-form-input
                    class="pcnt--inp"
                    type="number"
                    placeholder="от   %"
                    v-model="internalFilterParams.age_percent"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-3 col-12 pl-0">
              <b-form-group label-class="ws-nowrap" label="Пол аудитории">
                <b-form-checkbox
                  :checked="internalFilterParams.females_percent_min == 80"
                  @change="
                    () => {
                      internalFilterParams.females_percent_min = 80;
                      internalFilterParams.females_percent_max = null;
                    }
                  "
                >
                  От 80% женщин
                </b-form-checkbox>
                <b-form-checkbox
                  :checked="internalFilterParams.females_percent_max == 60"
                  @change="
                    () => {
                      internalFilterParams.females_percent_max = 60;
                      internalFilterParams.females_percent_min = null;
                    }
                  "
                >
                  От 40% мужчин
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="d-flex flex-wrap w-100 px-3 mr-0 ml-0">
            <div class="col-lg-3 col-12 pl-0">
              <b-form-group label-class="ws-nowrap" label="Охваты сторис">
                <div class="d-flex flex-row" style="max-width: 83%">
                  <b-form-input
                    v-model="internalFilterParams.coverage_stories_min"
                    type="number"
                    placeholder="От"
                  />
                  <b-form-input
                    class="ml-2"
                    v-model="internalFilterParams.coverage_stories_max"
                    type="number"
                    placeholder="До"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-3 col-12 pl-0">
              <b-form-group label-class="ws-nowrap" label="Охваты постов">
                <div class="d-flex flex-row" style="max-width: 83%">
                  <b-form-input
                    v-model="internalFilterParams.coverage_posts_min"
                    type="number"
                    placeholder="От"
                  />

                  <b-form-input
                    class="ml-2"
                    v-model="internalFilterParams.coverage_posts_max"
                    type="number"
                    placeholder="До"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-3 col-12 pl-0">
              <b-form-group label-class="ws-nowrap" label="Обновление карточек">
                <multiselect
                  style="max-width: 83%"
                  :options="updatePeriods"
                  placeholder="Выберите период"
                  select-label=""
                  deselectLabel=""
                  selectedLabel="Выбрано"
                  label="name"
                  :searchable="false"
                  track-by="value"
                  :value="
                    internalFilterParams.screens_last_updated
                      ? JSON.parse(internalFilterParams.screens_last_updated)
                      : null
                  "
                  @input="
                    (val) => {
                      internalFilterParams.screens_last_updated = JSON.stringify(val);
                    }
                  "
                >
                  <template slot="tagPlaceholder">Выберите период</template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import Multiselect from 'vue-multiselect';
import CitySelect from '@main/components/reusable/elements/selects/CitySelect.vue';
import SideModal from '../reusable/modals/MobileSideModal.vue';
import EventBus from '../../event-bus';
import Div from '../reusable/elements/Div.vue';

export default {
  components: {
    Multiselect,
    Div,
    CitySelect,
  },
  model: {
    prop: 'filterParams',
    event: 'input',
  },
  props: {
    filterParams: Object,
  },

  computed: {
    ...mapState(['currentUser', 'tags', 'countries', 'cities', 'bloggerOptions']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
  },
  data() {
    return {
      extraFilters: false,
      disclosedReview: false,
      selectedTags: [],
      adType: null,
      filterOpen: false,
      isMobile,
      adTypes: [
        { id: 1, name: 'Сторис' },
        { id: 2, name: 'Фото-пост' },
        { id: 3, name: 'Видео-пост' },
        { id: 4, name: 'Пост + сторис' },
        { id: 5, name: 'Гив' },
      ],
      updatePeriods: [
        { name: '2 недели', value: { days: 14 } },
        { name: '1 месяц', value: { days: 30 } },
        { name: '2 месяца', value: { days: 60 } },
        { name: '3 месяца', value: { days: 90 } },
        { name: '6 месяцев', value: { days: 180 } },
      ],
      SideModal,
      Div,
      internalFilterParams: {},
    };
  },
  watch: {
    disclosedReview(val) {
      this.$emit('disclosed', val);
    },
    filterParams: {
      deep: true,
      immediate: true,
      handler(val) {
        this.internalFilterParams = this.lodash.cloneDeep(val);
      },
    },
  },
  methods: {
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    resetFilterParams() {
      this.$emit('reset');
    },
    setFilterParams(data) {
      this.$emit('input', { ...this.internalFilterParams, ...data });
    },
    togglePriceMeta(n) {
      const index = this.internalFilterParams.price_meta.indexOf(n);
      if (index !== -1) this.internalFilterParams.price_meta.splice(index, 1);
      else this.internalFilterParams.price_meta.push(n);
    },
    async search() {
      await this.setFilterParams({});
      await this.$emit('search');
    },
  },
  mounted() {
    EventBus.$on('openReviewsFilter', () => {
      this.filterOpen = true;
    });
  },
};
</script>

<style scoped lang="scss">
.col-other {
  width: 200px !important;
  margin-left: 20px !important;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.filter__unsub--cover {
  z-index: 20 !important;
  position: absolute !important;
  width: 100% !important;
  height: 80% !important;
  bottom: 10px;
}
.pay__filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 15px;
  margin-bottom: 25px;
  z-index: 35;
  opacity: 1;
}
.pay__filters--text {
  margin-right: 15px;
}
.pcnt--inp {
  max-width: 60px !important;
  margin-left: 7px;
}
@media (max-width: 768px) {
  .pay__filters {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 70px;
    margin-right: 0px;
  }
  .pay__filters--text {
    font-size: 16px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .pay--filters_btn {
    font-size: 13px;
  }
}
@media (max-width: 330px) {
  .pay__filters--text {
    font-size: 12px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .pay--filters_btn {
    font-size: 12px;
  }
}
.percentage__form {
  .percentage__select--type {
    width: 204px !important;
    padding-right: 0px;
  }
  .percentage__input--percent {
    padding: 0px;
    margin-left: 4px;
    margin-right: 15px;
  }
  input {
    min-height: 33.5px !important;
    padding: 0 5px;
    text-align: right;
  }
}
@media (max-width: 768px) {
  .standard__input--mobile {
    padding: 0px !important;
    margin: 0px !important;
  }
  .standard__input--mobile:not(:first-child) {
    margin-left: 10px !important;
  }
  .percentage__input {
    max-width: 50px !important;
  }
  .percentage__form {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

::v-deep .multiselect__tags {
  border: 1px solid #ced4da !important;
}
::v-deep .multiselect__placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

::v-deep .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.percentage__input--percent {
  height: 33.5px !important;
}
.custom-control {
  padding-left: 2.09rem !important;
}

::v-deep .multiselect__content {
  max-width: 100%;
}

::v-deep .multiselect__tag {
  background: #3897f0 !important;
  margin: 0px !important;
}

::v-deep .multiselect__option--highlight {
  background: #3897f0 !important;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 0px !important;
  padding: 0px !important;
}

::v-deep .multiselect,
::v-deep .multiselect__input,
::v-deep .multiselect__single {
  font-size: 14px !important;
}

::v-deep .multiselect__single {
  padding-left: 5px !important;
  margin-bottom: 0px !important;
  margin-top: 2px !important;
  line-height: 21px !important;
}

::v-deep .multiselect__input {
  margin-bottom: 4px !important;
}

::v-deep .multiselect__select {
  height: 33px !important;
  top: 0px !important;
}

::v-deep .multiselect__tag-icon:after {
  color: #fff;
}

::v-deep .multiselect__tags {
  min-height: 33.5px !important;
  padding: 4px 30px 0px 8px !important;
}

::v-deep .multiselect {
  min-height: 33.5px !important;
}
::v-deep .option--active .option__select::after {
  left: 5px;
}
@media (max-width: 768px) {
  ::v-deep .option--active .option__select::after {
    width: 6px !important;
    height: 13px !important;
    left: 8.4px !important;
  }
}
::v-deep .custom-control {
  padding-left: 14px !important;
}
</style>
