<template>
  <footer class="footer">
    <div
      class="container d-flex flex-column flex-md-row justify-content-around align-items-center text-center"
    >
      <img
        src="../assets/footer_logo.png"
        style="filter: invert(1); max-width: 200px; margin-bottom: 1rem"
      />
      <div class="footer__links flex flex--drcth">
        <a
          target="_blank"
          :href="link"
          class="footer__link"
          v-for="(link, title) in footerLinks"
          :key="link"
          >{{ title }}</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['footerLinks']),
  },
};
</script>

<style scoped>
.footer {
  background-color: #424858;
  width: 100%;
  padding: 13px 0;
  position: absolute !important;
  bottom: 0;
}
.footer__links {
  min-height: 60px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.footer__links a {
  color: #fff;
  font-size: 16px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

@media (min-width: 1024px) {
  .footer__links a {
    font-size: 14px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .footer__links a {
    width: 100%;
    line-height: 26px;
    font-size: 14px;
  }
}

.flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
</style>
