import apiService from './index';
import { getNotificationsSettings } from './notify';

const THEMES_LIST_URL = `/api/core/data/themes/`;
const COUNTRIES_LIST_URL = `/api/core/data/countries/`;
const CITIES_LIST_URL = '/api/core/data/cities/';
const THEMES_AGGREGATION_URL = `/api/core/data/themes/get_tags_aggregation/`;

const EXCLUDED_THEMES = ['Нет отзывов', 'Нет темы'];

export default {
  async getCountries(params) {
    const response = await apiService.get(COUNTRIES_LIST_URL, { params });
    return response.results;
  },
  async getThemes(params) {
    const response = await apiService.get(THEMES_LIST_URL, { params });
    return response.results.filter((val) => !EXCLUDED_THEMES.includes(val.name));
  },
  async getCities(params) {
    const response = await apiService.get(CITIES_LIST_URL, { params });
    return response.results;
  },
  async getThemesAggregation(params) {
    const response = await apiService.get(THEMES_AGGREGATION_URL, { params });
    return response;
  },
};
