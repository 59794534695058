import store from './store';

export default {
  isAuthorised() {
    return !!store.state.currentUser;
  },
  isStaff() {
    return store.getters.isStaff;
  },
};
