export default {
  INIT: 0,
  LOADING: 1,
  ERROR: 2,
  LOADED: 3,
  bloggers: {
    moderation_statuses: {
      M_NOT_MODERATED: 1,
      M_ACCEPTED: 2,
      M_FOR_REVISION: 3,
      M_REJECTED: 4,
      M_BANNED: 5,
      M_CHANGED: 6,
      groups: {
        safe: [2, 6],
        unsafe: [1, 3, 4, 5],
        onModeration: [1, 6],
      },
    },
  },
};
