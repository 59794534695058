import apiService from './index';
/* eslint-disable camelcase */

export default {
  energy: {
    async getPrice() {
      const response = await apiService.get('/api/payments/energy_price/');
      return response;
    },
    async createPayment(price_id) {
      const response = await apiService.post('/api/payments/energy/create/', { price_id });
      return response;
    },
  },
  sub: {
    async createPayment() {
      const response = await apiService.post('/api/payments/services-subscription/create/');
      return response;
    },
    async recurrentInit() {
      const response = await apiService.post('/api/payments/recurrent_init/create/');
      return response;
    },
    async charge() {
      const response = await apiService.post('/api/payments/subscribe/charge/');
      return response;
    },
    payments: {
      async list(params) {
        const response = await apiService.get('/api/payments/subscription_payments/', { params });
        return response;
      },
    },
  },
};
