import { update } from 'lodash';
import qs from 'qs';
import apiService from './index';

export default {
  settings: {
    async retrieve(name) {
      const response = await apiService.get(`/api/bot/settings/${name}/`);
      return response;
    },
    async partial_update(name, data) {
      const response = await apiService.put(`/api/bot/settings/${name}/`, data);
      return response;
    },
  },
  groups: {
    async retrieve(name) {
      const response = await apiService.get(`/api/bot/groups/${name}/`);
      return response;
    },
    async partial_update(name, data) {
      const response = await apiService.put(`/api/bot/groups/${name}/`, data);
      return response;
    },
  },
  userPreorder: {
    async update(data) {
      const result = apiService.put(`/api/bot/preorders/me/`, data);
      return result;
    },
    async get() {
      const result = apiService.get(`/api/bot/preorders/me/`);
      return result;
    },
    async order(data) {
      const result = apiService.post(`/api/bot/preorders/order/`, data);
      return result;
    },
  },
  formats: {
    async list(params) {
      const result = apiService.get(`/api/bot/formats/`, {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
      });
      return result;
    },
  },
};
