import Vue from 'vue';
import { router } from '@main/router';
import tools from '@main/store/tools';

export default {
  namespaced: true,
  state: {
    changePasswordModal: {
      open: false,
      context: {
        phone: null,
      },
    },
    changeBotSettingsModal: false,
    statisticCardModal: {
      open: false,
      context: {
        instaname: null,
      },
    },
    instagramVerificationModal: {
      open: false,
      context: {
        prAccount: false,
      },
    },
  },
  mutations: {
    toggleChangePasswordModal(state, params) {
      const newState = { ...state.changePasswordModal, ...params };
      Vue.set(state, 'changePasswordModal', newState);
    },
    toggleInstagramVerificationModal(state, params) {
      const newState = { ...state.instagramVerificationModal, ...params };
      Vue.set(state, 'instagramVerificationModal', newState);
    },
    toggleStatisticCardModal(state, params) {
      const newState = { ...state.statisticCardModal, ...params };
      Vue.set(state, 'statisticCardModal', newState);
    },
    toggleChangeBotSettingsModal(state, bool) {
      Vue.set(state, 'changeBotSettingsModal', bool);
    },
  },
};
