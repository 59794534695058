import Vue from 'vue';
import promobotApi from '@main/api/promobot';

export default {
  namespaced: true,
  state: {
    refuseReasons: [],
  },
  mutations: {
    setRefuseReasons(state, data) {
      Vue.set(state, 'refuseReasons', data);
    },
  },
  actions: {
    async loadRefuseReasons({ commit, state }) {
      const reasons = await promobotApi.orders.refuseReasons.list();
      commit('setRefuseReasons', reasons.results);
      console.log(state.refuseReasons);
    },
  },
};
