<template>
  <modal
    :show="showSubscriptionPriceModal"
    @hide="() => toggleSubscriptionPriceModal(false)"
    title="Выберите тариф"
    mainClass="popup-new-service"
    wrapClass="popup-new-service__wrap modal-md"
  >
    <div v-if="prices" class="popup__content popup-new-service__content">
      <h3 class="popup-new-service__title">Тарифы</h3>
      <template>
        <a
          @click="createPayment(price.id)"
          class="popup-new-service__item"
          v-for="(price, i) in prices"
          :key="i"
        >
          <div class="popup-new-service__item-col">
            <span class="popup-new-service__item-title"
              >{{ price.title }} {{ price.days === 30 ? 'подписки' : '' }} +
              {{ price.days != 365 ? Math.floor(10 * (price.days / 30)) : 120 }} ⚡️</span
            >
            <p class="popup-new-service__item-total">
              {{ price.price_per_month }} &#8381; в мес.
              <span class="popup-new-service__item-price">
                / {{ Math.round(price.price_per_month * (price.days / 30)) }} &#8381;
                <span class="small"></span>
              </span>
            </p>
          </div>
          <div class="popup-new-service__item-col d-none d-xsm-flex">Оплатить</div>
        </a>
      </template>
      <!--p class="popup-new-service-result__text">
        В случае, если модератор отклонит вашу заявку на покупку премиум аккаунта, сервис
        автоматически вернет вам деньги. Цена в расчете за день указана за календарные дни.
      </!--p-->
    </div>
    <Loader v-else />
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';
import payments from '@main/api/payments';
import { mapState, mapMutations } from 'vuex';
import Loader from '@main/components/Loader.vue';

export default {
  components: {
    Modal,
    Loader,
  },
  data: () => ({
    prices: null,
  }),
  computed: {
    ...mapState(['currentUser']),
    ...mapState('payments', ['showSubscriptionPriceModal']),
  },
  methods: {
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    async createPayment(paymentId) {
      if (!this.currentUser) {
        this.toggleSignIn(true);
        this.toggleSubscriptionPriceModal(false);
        return;
      }
      const response = await payments.sub.createPayment(paymentId);
      if (response.url) {
        window.location = response.url;
      }
      this.toggleSubscriptionPriceModal(false);
    },
  },
  watch: {
    async showSubscriptionPriceModal(val) {
      if (val) {
        const res = await payments.sub.getPrice();
        this.prices = res.results;
        console.log(this.prices);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.overline {
  text-decoration: line-through;
  margin-bottom: 5px;
  color: gray;
}
.discount {
  margin-left: 5px;
}
@media (max-width: 330px) {
  .popup-new-service__item-total {
    font-size: 19px !important;
  }
}
</style>
