import { render, staticRenderFns } from "./InstagramVerificationWindow.vue?vue&type=template&id=88c700f6&scoped=true&"
import script from "./InstagramVerificationWindow.vue?vue&type=script&lang=js&"
export * from "./InstagramVerificationWindow.vue?vue&type=script&lang=js&"
import style0 from "./InstagramVerificationWindow.vue?vue&type=style&index=0&id=88c700f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88c700f6",
  null
  
)

export default component.exports