<template>
  <base-popup :data="data" @close="$emit('close', data)" />
</template>

<script>
import BasePopup from './BasePopup.vue';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    BasePopup,
  },
};
</script>
