<template>
  <section class="full-table section mb-4">
    <div class="rectangle-block-wrapper">
      <div
        class="rectangle-block"
        :class="{
          'mode--blogger': filterParams.customer_kind === 'blogger',
          'mode--commerce': filterParams.customer_kind === 'shop',
        }"
      >
        <div class="upper-part">
          <div class="left-part">
            <h1 class="left-part__title rev-title d-none d-llg-inline-block">
              Показана таблица для
            </h1>
            <h2 class="left-part__title d-llg-none mr-sm-3">Таблица для</h2>
            <div class="d-flex ml-md-2 ml-lg-2">
              <b-button
                class="btn btn-rev short-btn"
                size="md"
                :variant="filterParams.customer_kind === 'blogger' ? 'primary' : 'outline-default'"
                @click="
                  () => {
                    $set(filterParams, 'customer_kind', 'blogger');
                    pageChangeHandler(1);
                  }
                "
              >
                Блогеров
              </b-button>
              <b-button
                style="max-width: none; width: auto !important"
                class="btn btn-rev short-btn"
                size="md"
                :variant="filterParams.customer_kind === 'shop' ? 'primary' : 'outline-default'"
                @click="
                  () => {
                    $set(filterParams, 'customer_kind', 'shop');
                    pageChangeHandler(1);
                  }
                "
              >
                Коммерции
              </b-button>
            </div>
            <b-input-group class="custom-search-field ml-2 ml-llg-4">
              <b-form-input
                v-model.lazy="filterParams.item_q"
                type="text"
                placeholder="Поиск (товар или услуга)"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="updateData" variant="outline-default"
                  ><b-icon icon="search"
                /></b-button>
              </b-input-group-append>
            </b-input-group>
            <!--form- class="custom-search-field mr-2" @submit.prevent="updateData()">
              <input
                class="custom-search-field__field input"
                placeholder="Название товара/услуги"
                type="text"
                
              />
              <b-button
                class="ml-1"
                variant="primary"
                style="border: none !important"
                type="submit"
              >
                <i class="fas fa-search"></i>
              </b-button>
            </!--form-->
            <b-button
              style="min-height: 33.5px !imporatnt"
              v-if="!disableLikedBtn"
              :disabled="!currentUser || !currentUser.liked_reviews"
              @click="
                () => {
                  if (filterParams.mode === 'liked') {
                    $set(filterParams, 'mode', 'all');
                  } else {
                    $set(filterParams, 'mode', 'liked');
                  }
                  pageChangeHandler(1);
                }
              "
              :variant="
                currentUser && currentUser.liked_reviews
                  ? filterParams.mode === 'liked'
                    ? 'primary'
                    : 'outline-default'
                  : 'dark'
              "
              class="ml-2 ml-llg-5 d-none d-mmd-block"
              ><div class="d-flex align-items-center">
                <img
                  v-if="!currentUser || !currentUser.liked_reviews"
                  class="d-inline-block mr-2"
                  src="@main/assets/img/svg/like-white-outline.svg"
                  alt=""
                /><img class="d-inline-block mr-2" v-else src="@main/assets/img/svg/like.svg" />
                <span
                  class="btn-lk-fv"
                  :style="{
                    color:
                      !currentUser || !currentUser.liked_reviews || filterParams.mode === 'liked'
                        ? 'white'
                        : 'initial',
                  }"
                  >Избранные отзывы: {{ currentUser ? currentUser.liked_reviews : 0 }}
                </span>
                <span
                  class="btn-lk-fv-com"
                  :style="{
                    color:
                      !currentUser || !currentUser.liked_reviews || filterParams.mode === 'liked'
                        ? 'white'
                        : 'initial',
                  }"
                  >{{ currentUser ? currentUser.liked_reviews : 0 }}
                </span>
              </div></b-button
            >
          </div>
          <div class="right-part only-desktop only-desktop--flex">
            <span class="gray-text">Показывать по</span>
            <div class="select-wrapper">
              <select class="select" v-model="filterParams.page_size">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
        <slot name="post-header-mobile"></slot>
        <div>
          <div v-if="!reviewsLoading">
            <div
              class="mouseover-tooltip only-desktop only-desktop--block"
              id="mouseover-tooltip"
            ></div>
            <div class="plus-tooltip only-desktop only-desktop--block" id="plus-tooltip">
              Все отзывы на блогера
            </div>
            <div class="mobile-tooltip only-mobile only-mobile--block" id="mobile-tooltip">
              Это блогер, который рекламировал блог заказчика рекламы или его товар/услугу
            </div>
            <div class="nothing-found px-5" v-if="!reviews.length">
              <div v-if="bans && bans.length">
                Блогер {{ bans[0].instaname }} занесен в черный список сайта отзывов
                {{ bannedForever && bannedForever.length ? ' навсегда.' : '.' }}
                <span v-if="!bannedForever || !bannedForever.length">
                  Чтобы выйти из черного списка, нужно набрать подряд
                  {{ leftBanReviews || 10 }} положительных отзывов.
                </span>
                <b-button style="font-size: 18px" variant="link" href="/rules/" target="_blank"
                  >Правила сайта отзывов</b-button
                >
              </div>
              <div v-else>По заданным критериям поиска нет результатов</div>
            </div>
            <b-table
              class="reviews-table mt-3"
              ref="rev-table"
              responsive
              striped
              bordered
              v-if="!isMobile"
              v-show="reviews.length"
              :fields="fields"
              :items="reviewsItems"
            >
              <template #head()="data">
                <div :class="['align-middle']">
                  {{ data.label }}
                </div>
              </template>

              <template #cell(index)="data">
                <span
                  :title="`Еще ${data.item.reviews_count_advertiser}`"
                  class="one-btn"
                  @click.prevent="toggleMoreByBlogger(data.item.id)"
                  v-if="
                    !data.item.referencedBy &&
                    data.item.reviews_count_advertiser &&
                    filterParams.mode === 'all' &&
                    !loading.includes(data.item.id)
                  "
                  >{{ data.item.showMore ? '–' : '+' }}</span
                >
                <div v-else-if="loading.includes(data.item.id)" class="vertical-align-middle">
                  <b-spinner class="mx-2" style="width: 15px; height: 15px" variant="secondary">
                  </b-spinner>
                </div>
              </template>

              <template #cell(customer)="data">
                <b-button
                  class="text-left"
                  variant="link"
                  v-b-tooltip
                  :title="data.value"
                  style="color: #3897f0"
                  :href="getInstagramUrl(data.value)"
                  target="_blank"
                  >{{ data.value }}</b-button
                >
              </template>
              <template #cell(advertiser)="data">
                <b-button
                  class="text-left"
                  variant="link"
                  v-b-tooltip
                  :title="data.value"
                  style="color: #3897f0"
                  :href="getInstagramUrl(data.value)"
                  target="_blank"
                  >{{ data.value }}</b-button
                >
              </template>
              <template #cell(stat_btn)="data">
                <div v-if="data.item.advertiser_blogger">
                  <small class="d-inline-block text-truncate stat-col-blogger">{{
                    data.item.advertiser
                  }}</small>
                  <br />
                  <div class="d-flex flex-row align-items-center justify-content-start">
                    <b-button
                      @click="() => $emit('show-stat', String(data.item.advertiser))"
                      variant="link"
                      >Посмотреть</b-button
                    >
                  </div>
                </div>
                <div class="d-none hidden-stat-btn" v-else>
                  <small class="d-inline-block text-truncate stat-col-blogger">Нет данных</small>
                  <b-button class="text-left" :to="{ name: 'fill_statistics' }" variant="link"
                    >Заполнить</b-button
                  >
                </div>
              </template>
              <template #cell(rate)="data">
                {{ data.value }} <img class="rev_star" src="@main/assets/img/svg/star.svg" alt="" />
              </template>

              <template #cell(text_display)="row">
                <b-button v-if="row.item.text_display" variant="link" @click="row.toggleDetails">{{
                  row.detailsShowing ? 'Закрыть' : 'Открыть'
                }}</b-button>
              </template>

              <template #cell(customer_tags)="data">
                <div class="review-tags" v-b-tooltip.hover :title="data.value">
                  <span v-line-clamp:20="2">{{ data.value }}</span>
                </div>
              </template>

              <template #cell(date)="data">
                <div class="d-flex flex-row justify-content-center">
                  <div>
                    <p style="font-size: 10pt !important; margin-bottom: 1px" class="text-grey">
                      № {{ data.item.id }}
                    </p>
                    {{ data.value }}
                  </div>
                </div>
              </template>

              <template #cell(processed)="data">
                <p @click="showReason(data.item)" v-html="data.value"></p>
              </template>
              <template #cell(delete_review)="data">
                <div
                  @click="del(data.item.id)"
                  class="btn d-flex flex-row align-items-center text-align-center justify-content-center"
                >
                  <svg class="icon-close-btn icon-hover-close">
                    <use xlink:href="@main/assets/sprite.svg#close-btn"></use>
                  </svg>
                </div>
              </template>
              <template #row-details="row">{{ row.item.text_display }} </template>
              <template #cell(liked)="data">
                <div
                  @click="like(data.item)"
                  style="cursor: pointer; min-width: 20px !important"
                  class="d-flex flex-column align-items-center"
                >
                  <template v-if="!data.item.liked_by_viewer">
                    <img
                      class="rev-lk like-btn-gr"
                      src="@main/assets/img/svg/like-gray-outline.svg"
                      alt=""
                    />
                    <img
                      class="rev-lk d-none like-btn-hov"
                      src="@main/assets/img/svg/like-outline.svg"
                      alt=""
                    />
                  </template>
                  <img class="rev-lk" v-else src="@main/assets/img/svg/like.svg" alt="" />
                  <span v-if="data.value" class="text-center fs-12 gray-text">{{
                    data.value
                  }}</span>
                </div>
              </template>
            </b-table>
            <div :key="disclosed" class="reviews-mobile row container p-0 rev-mb" v-else>
              <template v-for="review in formatedReviews">
                <mobile-review
                  :disclosed="disclosed"
                  :ref="`mobileReview${review.id}`"
                  :review="review"
                  :loading="loading"
                  :excludedFields="excludedFields"
                  :filterParams="filterParams"
                  :key="`${review.referencedBy}-${review.id}-H871dc`"
                  @show-more="toggleMoreByBlogger(review.id)"
                  @hide-more="toggleMoreByBlogger(review.id)"
                  @del="del"
                  @edit="edit"
                  @like="like"
                  @show-stat="(event) => $emit('show-stat', event)"
                ></mobile-review>
              </template>
            </div>
            <!--div
            
              v-if="reviews && reviews.length"
              style="background-color: #fffce3; border-bottom: 1px solid #dee2e6"
              class="d-none d-xl-flex justify-content-center align-items-center pb-3 flex-wrap"
            >
              <p class="mt-3">
                <span style="font-weight: 500">Снизьте цену за подписчика до 72%</span> и проверьте
                окупаемость рекламы ещё на этапе выбора блогеров вместе с Трекером рекламы
              </p>
              <b-button href="/tracker/" class="mt-3 ml-3" variant="outline-default"
                >Открыть Трекер рекламы</b-button
              >
            </!--div-->
            <div
              v-if="
                totalPages > 1 &&
                reviews &&
                !reviews.length <= 0 &&
                filterParams.page != totalPages &&
                showMoreTimes + filterParams.page != totalPages
              "
              class="show-more"
              @click="!showMoreLoader ? showMore() : null"
            >
              <template v-if="!showMoreLoader">Показать еще</template>
              <loader v-else />
            </div>
          </div>
          <loader v-else />
          <notification-window />
        </div>
        <Pagination
          v-if="totalPages > 1"
          :current="filterParams.page"
          :total="totalPages"
          @page-change="pageChangeHandler"
        />
      </div>
    </div>
  </section>
</template>

<script>
import clone from 'clone';
import { isMobile } from 'mobile-device-detect';
import { mapState, mapMutations } from 'vuex';
import wrappers from '@main/func/wrappers';
import Loader from '@main/components/Loader.vue';
import Pagination from '@main/components/Pagination.vue';
import AlertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import NotificationWindow from '@main/components/notifications/NotificationWindow.vue';
import MobileReview from './MobileReview.vue';
import reviewsApi from '../../api/reviews';

const cellClass = ['align-middle', 'review__table--cell'];
const thClass = ['align-middle', 'font-weight-bold--revtable'];
const defField = { class: cellClass, thClass };

export default {
  components: {
    Loader,
    MobileReview,
    NotificationWindow,
    Pagination,
  },
  model: {
    prop: 'filterParams',
    event: 'input',
  },
  props: [
    'blogger',
    'kind',
    'filterParams',
    'excludedFields',
    'disableLikedBtn',
    'queryFields',
    'disclosed',
  ],
  data() {
    return {
      showMoreLoader: false,
      reviews: [],
      isMobile,
      showMoreTimes: 0,
      lastRequestId: null,
      totalPages: 0,
      bans: [],
      loading: [],
      reviewsLoading: true,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('reviewsModeration', ['refuseReasons']),
    ...mapState(['tags']),
    fields() {
      let fieldArray = [
        { label: ' ', key: 'index', ...defField, class: [...cellClass, 'index-col'] },
        {
          label: 'Дата рекламы',
          key: 'date',
          formatter: this.$options.filters.formatDate,
          ...defField,
          class: ['align-middle', 'review__table--cell', 'date__cell'],
        },
        {
          label: 'Аккаунт заказчика',
          key: 'customer',
          ...defField,
          class: [...cellClass, this.filterParams.customer_kind === 'shop' ? 'cus__cell_comm' : ''],
        },
        {
          label: 'Аккаунт исполнителя',
          key: 'advertiser',
          ...defField,
          class: [...cellClass, this.filterParams.customer_kind === 'shop' ? 'adv__cell_comm' : ''],
        },
        this.filterParams.customer_kind === 'shop'
          ? {
              label: 'Реклама окупилась',
              key: 'paid_off',
              formatter(val) {
                return val ? 'Да' : 'Нет';
              },
              ...defField,
            }
          : {},
        {
          label: 'Цена за подписчика',
          key: 'price_per_one',
          formatter: this.getPricePerOne,
          ...defField,
          class: [...cellClass, 'ppo__cell'],
        },
        {
          label: 'Приход',
          key: 'arrival',
          ...defField,
          formatter(val) {
            if (val) return Number(val).toLocaleString().replace(/,/g, ' ');
          },
        },
        { label: 'Цена за рекламу', key: 'price', formatter: this.getPrice, ...defField },
        {
          label: 'Охваты из рекламы',
          key: 'largest_reach',
          formatter(val) {
            if (val) return Number(val).toLocaleString().replace(/,/g, ' ');
          },
          ...defField,
          class: [...cellClass, 'cov__cell'],
        },
        this.filterParams.mode === 'all'
          ? {
              label: 'Статистика блогера',
              key: 'stat_btn',
              ...defField,
              class: [...cellClass, 'stat__cell'],
            }
          : {},
        { label: 'Оценка', key: 'rate', ...defField },
        {
          label: 'Формат рекламы',
          key: 'ad_type',
          formatter: (value) => {
            return ['Сторис', 'Фото-пост', 'Видео-пост', 'Сторис + пост', 'Гив'][value - 1];
          },
          ...defField,
        },
        this.filterParams.customer_kind === 'shop'
          ? {
              label: 'Товар или услуга',
              key: 'item',
              ...defField,
              class: [...cellClass, 'tag__cell'],
            }
          : {
              label: 'Темы аккаунта',
              key: 'customer_tags',
              formatter: (value) => {
                return value.map((tag) => tag.name).join(', ');
              },
              ...defField,
              class: [...cellClass, 'tag__cell'],
            },
        this.filterParams.mode === 'me'
          ? { label: 'Статус', key: 'processed', ...defField, formatter: this.reviewStatus }
          : null,
        { label: 'Текст отзыва', key: 'text_display', ...defField },
        this.filterParams.mode === 'me'
          ? { label: 'Удалить', key: 'delete_review', ...defField }
          : null,
        this.filterParams.customer_kind === 'blogger'
          ? { label: ' ', key: 'liked', ...defField }
          : null,
      ];
      fieldArray = this.lodash.compact(fieldArray);
      if (this.excludedFields) {
        return fieldArray.filter((field) => !this.excludedFields.includes(field.key));
      }
      return fieldArray;
    },
    reviewsItems() {
      return this.lodash.sortBy(this.reviews, [
        (o) => {
          return o.advertiser_blogger;
        },
      ]);
    },
    formatedReviews() {
      let reviews = clone(this.reviewsItems);
      if (reviews)
        reviews = reviews.map((review) => {
          for (let k = 0; k < this.fields.length; k += 1) {
            const field = this.fields[k];
            if (field && field.formatter)
              review[field.key] = field.formatter(review[field.key], field.key, review);
          }
          return review;
        });
      else reviews = [];
      return reviews;
    },
    bannedForever() {
      if (!this.bans) {
        return;
      }
      return this.bans.filter((val) => val.forever);
    },
    leftBanReviews() {
      if (!this.bans) {
        return;
      }
      const ban = this.bans.filter((val) => !val.forever)[0];
      if (ban.positive_reviews >= 10) {
        return;
      }
      return 10 - ban.positive_reviews;
    },
  },
  methods: {
    ...mapMutations('notifications', ['setWindow', 'showMessage']),
    ...mapMutations(['setUserData']),
    async updateData({ showMore } = {}) {
      if (!showMore) {
        this.reviewsLoading = true;
      }
      this.bans = null;
      const randomRequestId = Math.floor(Math.random() * 1000000);
      this.lastRequestId = randomRequestId;
      let params = this.getParams();
      if (this.queryFields) {
        params = { ...params, ...{ fields: this.queryFields } };
      }
      if (!showMore) {
        this.reviews = [];
        this.page = 1;
      }
      let response;
      if (!showMore) {
        response = await reviewsApi.list(params);
      } else {
        response = await reviewsApi.list({
          ...params,
          page: (params.page || 1) + this.showMoreTimes,
        });
      }
      if (response && randomRequestId === this.lastRequestId) {
        this.totalPages = response.total_pages;
        let { results } = response;
        results = results.map((x) => ({
          ...x,
          showMore: false,
          price_per_one: x.price / x.arrival,
        }));
        this.reviews.push(...results);
        if ((!results || !results.length) && params.executor) {
          const bans = await reviewsApi.bans.list({
            instaname: params.executor,
            fields: 'instaname,banned_date,forever,is_banned,positive_reviews',
            is_banned: true,
            ordering: '-banned_date',
          });
          this.bans = bans.results;
        }
      }
      this.reviewsLoading = false;
    },
    getParams() {
      const params = this.lodash.cloneDeep(this.filterParams);
      params.format = 'json';
      params.type = params.ad_type;
      if (params.customer_kind === 'blogger') delete params.item_q;
      if (params.customer_kind === 'shop') delete params.tags;
      delete params.ad_type;
      return params;
    },
    async showMore() {
      try {
        this.showMoreLoader = true;
        this.showMoreTimes += 1;
        await this.updateData({ showMore: true });
      } catch (e) {
        this.showMoreLoader = false;
        if (e.response.status >= 500) {
          this.setWindow({
            text:
              'Произошла ошибка при загрузке отзывов. Перезагрузите страницу и попробуйте еще раз или обратитесь в техническую поддержку!',
            iconPath: AlertGreyIcon,
          });
        }
      } finally {
        this.showMoreLoader = false;
      }
    },
    getInstagramUrl(i) {
      return `https://www.instagram.com/${i}/`;
    },
    getPricePerOne(value, key, review) {
      const textParts = [];
      if (review.price_meta) {
        if (review.price_meta === 'together') textParts.push('ВП');
        if (review.price_meta === 'barter') textParts.push('Бартер');
      } else if (review.price) {
        textParts.push(String(review.price_per_one.toFixed(1)).replace('.', ','));
        textParts.push('₽');
      }
      return textParts.join(' ');
    },
    getPrice(value, key, review) {
      let textParts = [];
      if (review.price_meta === 'together') textParts.push('ВП');
      if (review.price_meta === 'barter') textParts.push('Бартер');
      if (review.price_meta && review.price) textParts.push('+\n');
      if (review.price) {
        textParts = textParts.concat([this.$options.filters.beautifyNum(review.price), '₽']);
      }
      return textParts.join(' ');
    },
    async toggleMoreByBlogger(reviewId) {
      this.loading.push(reviewId);
      const review = this.reviews.filter((x) => x.id === reviewId)[0];
      if (!review.showMore) {
        const params = {
          max_count: 20,
          executor: review.advertiser,
          customer_kind: review.customer_kind,
        };
        const response = await reviewsApi.list(params);
        if (response) {
          review.showMore = true;
          this.$set(review, '_rowVariant', 'yellow');
          let { results } = response;
          results = results
            .map((x) => ({ ...x, showMore: false, referencedBy: review.id, _rowVariant: 'yellow' }))
            .filter((x) => x.id !== review.id);
          this.reviews.splice(this.reviews.indexOf(review) + 1, 0, ...results);
        }
      } else {
        this.$set(review, '_rowVariant', null);
        review.showMore = false;
        this.reviews = this.reviews.filter((x) => x.referencedBy !== review.id);
      }
      this.loading.splice(this.loading.indexOf(reviewId), 1);
    },
    toggleAllText() {
      console.log(this.$refs);
    },
    edit(id) {
      this.$router.push({ name: 'edit-review', params: { id } });
    },
    del(id) {
      this.setWindow({
        text: `Вы уверены, что хотите удалить отзыв №${id}`,
        buttons: [
          {
            text: 'Удалить',
            attrs: { variant: 'danger' },
            handler: async () => {
              try {
                await reviewsApi.delete(id);
                this.showMessage({
                  title: 'Отзыв удален с сайта',
                  message:
                    'Так-же в ближайшее время он будет удален из чатов, прикрепленных к @easyprbot',
                  icon: 1,
                });
                const idx = this.reviews.findIndex((val) => val.id === id);
                if (idx > -1) {
                  this.reviews.splice(idx, 1);
                }
              } catch (e) {
                console.log(e);
                this.showMessage({
                  title: 'Ошибка!',
                  message:
                    'Не удалось удалить отзыв, возможно он уже был удален! Перезагрузите страницу и попробуйте позже.',
                  icon: 2,
                });
              } finally {
                this.setWindow(null);
              }
            },
          },
          {
            text: 'Отмена',
            handler: () => {
              this.setWindow(null);
            },
          },
        ],
      });
    },
    showReason(review) {
      if (review.processed && !review.accepted) {
        const reasons = this.refuseReasons
          .filter((reason) => {
            return review.refuse_reasons.includes(reason.id);
          })
          .map((reason) => reason.text)
          .join('\n\n');
        this.setWindow({
          topLess: false,
          title: `Причина отклонения отзыва №${review.id}`,
          html: `${reasons || 'Причины не указаны'}`,
          buttons: [
            {
              text: 'Редактировать и отправить отзыв заново',
              attrs: { variant: 'outline-default' },
              handler: () => {
                this.edit(review.id);
                this.setWindow(null);
              },
            },
          ],
        });
      }
    },
    reviewStatus(value, key, review) {
      if (!this.filterParams.mode === 'me') {
        return;
      }
      if (!review.processed) {
        return 'На модерации';
      }
      // eslint-disable-next-line no-nested-ternary
      return review.accepted
        ? review.show_text
          ? '<span style="color: green">Опубликован</span>'
          : '<span style="color: green">Опубликован без текста</span>'
        : '<span style="color: red; cursor: pointer">Отклонен</span>';
    },
    async pageChangeHandler(page) {
      this.showMoreTimes = 0;
      await this.setFilterParams({ page });
      await this.updateData();
    },
    setFilterParams(data) {
      this.$emit('input', { ...this.filterParams, ...data });
    },
    async like(review) {
      wrappers.requiresAuth(async () => {
        const response = await reviewsApi.like(review.id);
        this.setUserData({
          liked_reviews: this.currentUser.liked_reviews + (review.liked_by_viewer ? -1 : 1),
        });
        this.$set(
          this.reviews,
          this.reviews.indexOf(this.reviews.find((val) => val.id === review.id)),
          { ...response, price_per_one: response.price / response.arrival }
        );
      }, '/reviews/');
    },
  },
  async mounted() {
    await this.updateData();
  },
};
</script>

<style scoped lang="scss">
.stat-col-blogger {
  color: #6a7b83;
  max-width: 80px;
}

.reviews-table >>> td {
  min-height: 63px !important;
}

.review-table >>> .align-middle {
  font-weight: 500px !important;
}

.show-more {
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #d5dfe4;
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #6a7b83;
}

.reviews-table >>> .b-table-details {
  background-color: #fffce3;
}

.reviews-table {
  margin-bottom: 0px !important;
}

::v-deep .table {
  border-right: none;
  border-left: none;
}

.review-tags {
  max-height: 2em;
  line-height: 1em;
  word-break: break-word;
}
.rev-mb {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.coverage__th-rev {
  min-width: 160px !important;
}
.icon-hover-close:hover {
  fill: red;
}

@media (max-width: 350px) {
  .short-btn {
    padding: 0.375rem 0.35rem;
  }
}
.leave_rev-block {
  // min-height: 300px;
  height: 300px !important;
  max-height: 300px !important;
  flex-direction: column;
}
.fillstat-btn {
  widows: 100%;
  margin: 1px;
  margin-top: 40px;
}
.rev-title {
  margin-right: 14px;
}

@media (min-width: 768px) {
  .btn-rev {
    width: 97px;
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .btn-rev {
    border-radius: 0;
  }
  .btn-rev:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .btn-rev:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
.button-fill-stat__mobile {
  display: block;
  width: 100%;
  border-bottom: 1px solid #d5dfe4;
  background-color: #fffce3;
}
@media (min-width: 400px) {
  .row-btns__rev {
    max-width: 300px !important;
  }
}
</style>
