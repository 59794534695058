import { render, staticRenderFns } from "./ModalStatistic.vue?vue&type=template&id=dfc5e426&scoped=true&"
import script from "./ModalStatistic.vue?vue&type=script&lang=js&"
export * from "./ModalStatistic.vue?vue&type=script&lang=js&"
import style0 from "./ModalStatistic.vue?vue&type=style&index=0&id=dfc5e426&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfc5e426",
  null
  
)

export default component.exports