import parsers from '@main/func/parsers';

export default {
  isStaff: (state) => {
    return state.currentUser ? state.currentUser.is_staff : false;
  },
  hasTokens: () => {
    return window.$cookies.get('jwt_access') || localStorage.getItem('token');
  },
  getUserModel: (state) => {
    return parsers.djangoRestFrameworkMetadataParser(state.bloggerOptions.actions.POST);
  },
  subscriptionState: (state) => {
    if (!state.currentUser) {
      return {};
    }
    return {
      new:
        !state.currentUser.profile.subscribe &&
        !state.currentUser.profile.subscription_member &&
        !state.currentUser.services_subscriber,
      newSub:
        !state.currentUser.profile.subscribe &&
        !state.currentUser.profile.subscription_member &&
        state.currentUser.services_subscriber,
      active:
        state.currentUser.profile.subscribe &&
        state.currentUser.profile.subscription_member &&
        state.currentUser.services_subscriber,
      disabledByUser:
        !state.currentUser.profile.subscribe &&
        state.currentUser.profile.subscription_member &&
        state.currentUser.services_subscriber,
      ended:
        !state.currentUser.profile.subscribe &&
        state.currentUser.profile.subscription_member &&
        !state.currentUser.services_subscriber,
      error:
        state.currentUser.profile.subscription_member &&
        state.currentUser.subscribe_error &&
        !state.currentUser.services_subscriber,
      waiting:
        state.currentUser.profile.subscription_member &&
        state.currentUser.profile.subscribe &&
        !state.currentUser.services_subscriber,
    };
  },
};
