import Vue from 'vue';
import store from '@main/store/index';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import apiService from './index';

export default {
  async getMe() {
    try {
      const response = await apiService.get('/api/users/me/', {
        raxConfig: {
          retry: 3,
          retryDelay: 5000,
        },
      });
      return response;
    } catch (e) {
      if (e.response.status === 500) {
        store.commit('notifications/setWindow', {
          iconPath: alertGreyIcon,
          text:
            'Произошла ошибка при загрузке вашего профиля, проверьте подключение к интернету и/или повторите позже. При длительном присутствии ошибки - обратитесь к администрации для восстановления',
        });
      }
      return e;
    }
  },
  async updateMe(data) {
    const response = await apiService.put('/api/users/me/', data);
    return response;
  },
  async login(phone, password) {
    const response = await apiService.post('/api/token/', { phone, password });
    return response;
  },
  async register(phone, password, code) {
    const resp = await apiService.post('/api/users/register/', {
      phone,
      password,
      code,
      csrfmiddlewaretoken: Vue.$cookies.get('csrftoken'),
    });
    return resp;
  },
  async sendCode(data, params) {
    const response = await apiService.post(`/api/send-code/`, data, { params });
    return response;
  },
  async checkCode(codeId, code) {
    const config = { params: { code_id: codeId, code } };
    const response = await apiService.get('/api/check-code/', config);
    return response;
  },
  async changePhone(codeId, code) {
    const response = await apiService.post(`/api/change-phone/`, { code_id: codeId, code });
    return response;
  },

  async checkPassword(password) {
    const response = await apiService.post('/api/users/check_password/', { password });
    return response;
  },
  async changePassword(password, codeId, phone) {
    const data = { password, code_id: codeId, phone };
    const response = await apiService.post('/api/reset-password/', data);
    return response;
  },
  /**
   * Подтверждение email
   */
  async sendEmail(email, destination) {
    const response = await apiService.post('/api/users/send_mail/', { email, destination });
    return response;
  },

  /**
   * Подтверждение email
   */
  async checkEmail(token) {
    const response = await apiService.post('/api/users/check_mail/', { token });
    return response;
  },

  async changeEmail(email) {
    const response = await apiService.post('/api/users/change_mail/', { email });
    return response;
  },
};
