<template>
  <div
    style="z-index: 3 !important"
    class="notif-popup d-flex justify-content-between p-3 flex-column flex-sm-row"
    :class="variant"
  >
    <p
      v-html="message"
      class="text-left fs-12 mb-3 mb-sm-0"
      style="color: white; white-space: pre-wrap"
    ></p>
    <div class="d-flex justify-content-end justify-content-sm-between">
      <b-button
        class="fs-12 btn-notif"
        :key="`${button.id}-aj9hdn2,cniu12`"
        v-bind="button.attrs"
        v-for="(button, idx) in buttons"
        @click="
          () => {
            if (button.handler) {
              button.handler(button, idx);
            }
            $emit('read', idx);
          }
        "
        >{{ button.text }}</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: '',
    },
    message: String,
    buttons: Array,
  },
};
</script>

<style lang="scss" scoped>
.notif-popup {
  background: #3897f0;
  border-radius: 5px;
}

.notif-popup .danger {
  background: red;
}
.notif-popup .danger {
  background: red;
}
.btn-notif:not(:last-child) {
  margin-right: 10px;
}
</style>
