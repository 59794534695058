<template>
  <div :class="{ 'for-test': popupsCount > 0 }">
    <popup v-for="item in activePopups" :key="item.id" :data="item" @close="readMessage(item)" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import * as notifyApi from '@main/api/notify';
import Popup from './Popup.vue';

export default {
  components: {
    Popup,
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('notifications', ['activePopups', 'poups']),
    ...mapGetters('notifications', ['popupsCount']),
  },
  methods: {
    ...mapMutations('notifications', ['deletePopup', 'addPoup']),
    ...mapActions('notifications', ['readMessage']),
    async startWatch() {
      if (!this.currentUser) {
        return;
      }
      let lastId = this.poups.length > 0 ? this.poups[this.poups.length - 1].id : 0;
      const lastActiveId =
        this.activePopups.length > 0 ? this.activePopups[this.activePopups.length - 1].id : 0;
      lastId = lastActiveId > lastId ? lastActiveId : lastId;
      const respone = await notifyApi.CheckMessages(lastId);
      // eslint-disable-next-line no-restricted-syntax
      for (const p of respone) {
        this.addPoup(p);
      }
    },
    initWatch() {
      setInterval(this.startWatch, 5000);
    },
  },
  mounted() {
    this.initWatch();
  },
};
</script>

<style lang="scss" scoped></style>
