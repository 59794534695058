import { render, staticRenderFns } from "./StatisticCardModal.vue?vue&type=template&id=4fe3ba4c&scoped=true&"
import script from "./StatisticCardModal.vue?vue&type=script&lang=js&"
export * from "./StatisticCardModal.vue?vue&type=script&lang=js&"
import style0 from "./StatisticCardModal.vue?vue&type=style&index=0&id=4fe3ba4c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe3ba4c",
  null
  
)

export default component.exports