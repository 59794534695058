<template>
  <div>
    <div class="container">
      <ActionInfoModal @close="copyLink = false" :show="copyLink" v-if="copyLink">
        <template v-slot:body
          ><div class="modal-title--copy">Ссылка на карточку скопирована</div>
          <div style="line-break: anywhere" class="modal-link">
            {{ link }}
          </div>
          <div class="modal-link--opac">Вы можете отправить её любому рекламодателю</div></template
        >
      </ActionInfoModal>

      <div v-if="!user.foreign">
        <div
          v-if="!user.blogger_profile.hidden"
          :class="{ 'mycard-header': !user.foreign }"
          class="card__header"
        >
          <div
            class="justify-content-between justify-content-sm-start d-flex align-items-center flex-wrap"
          >
            <h1 class="mr-3 mr-sm-5 pr-1">Моя карточка</h1>

            <b-button
              :to="{ name: 'statistic-settings' }"
              class="py-2 my-2 px-2 px-sxm-3 mr-sm-4 redact-st"
              variant="primary"
              size="md"
              >Редактировать</b-button
            >

            <b-button
              class="py-2 my-2 px-2 px-sxm-3 mr-sm-4"
              size="md"
              variant="outline-default"
              :disabled="
                !user.blogger_profile.moderation_status === 2 || !user.blogger_profile.filled
              "
              v-clipboard:copy="link"
              @click="() => (copyLink = true)"
              style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden"
            >
              Скопировать ссылку
            </b-button>
            <b-button
              class="py-2 my-2 px-2 px-sxm-3 mr-sm-4"
              variant="outline-default"
              @click="cardAction('hide')"
              size="md"
            >
              👻 Скрыть с сайта
            </b-button>
          </div>
        </div>
        <div
          :class="{ 'mycard-header': !user.foreign }"
          v-else
          class="card__header card__header--hide"
        >
          <div class="d-flex align-items-center mb-3 mb-md-0 card__wrap--hide">
            <h1 class="statistics--card__title--hide">
              Моя карточка <span class="card__hide">скрыта</span>
            </h1>
            <b-button
              variant="outline-default"
              class="py-2 px-2 px-xsm-3 px-sssm-4"
              @click="cardAction('show')"
            >
              Показать
            </b-button>
          </div>
        </div>
      </div>
      <div v-else class="card__header card__header--hide">
        <div class="card__wrap card__wrap--hide">
          <div class="statistics--card__title statistics--card__title--hide">
            Статистика блогера
          </div>
        </div>
      </div>
      <div class="energy-pay--container__mobile" v-if="user.blogger_profile.prohibited">
        <div v-if="!currentUser || !currentUser.tracker_subscriber" class="reveal__card--block">
          <p class="reveal__card--text">
            Просмотр статистики блогеров, фильтры по статистике и другие сервисы доступны в подписке
            на сайт
          </p>
          <b-button
            href="http://sub.easyprbot.com/#tariffs"
            target="_blank"
            class="reveal__card--button mt-3"
            variant="warning"
            >Купить доступ за 1990 ₽</b-button
          >
        </div>
      </div>
      <section :class="{ 'card__section-stata': true, 'stat-mycard': !user.foreign }">
        <div
          v-if="![2].includes(Number(user.blogger_profile.moderation_status)) && !user.foreign"
          class="reveal__card--block py-3"
          style="height: unset"
        >
          <div v-if="user.blogger_profile.moderation_status == 1">
            Карточка на модерации, обычно это занимает 1 - 2 дня
          </div>
          <div v-else-if="user.blogger_profile.moderation_status == 6">
            Недавно вы изменили данные в карточке. Карточка отправлена на модерацию, обычно это
            занимает 1 - 2 дня
          </div>
          <div v-else>
            <p>Карточка отклонена модератором!</p>
            <p>Причина отклонения: {{ user.blogger_profile.reject_reason }}</p>
          </div>
        </div>

        <div class="energy-pay--container" v-if="user.blogger_profile.prohibited">
          <div v-if="!currentUser || !currentUser.tracker_subscriber" class="reveal__card--block">
            <p class="reveal__card--text">
              Просмотр статистики блогеров, фильтры по статистике и другие сервисы доступны в
              подписке на сайт
            </p>
            <b-button
              href="http://sub.easyprbot.com/#tariffs"
              target="_blank"
              class="reveal__card--button"
              variant="warning"
              >Купить доступ за 1990 ₽</b-button
            >
          </div>
        </div>
        <div class="account">
          <div class="account__header">
            <div class="account__header--left">
              <img
                v-if="graphql"
                :src="graphql.user.profile_pic_url"
                class="account__logo"
                alt=""
              />
              <img v-else src="@main/assets/img/no_image.jpg" alt="" class="account__logo" />
              <a
                target="_blank"
                :href="`https://instagram.com/${user.blogger_profile.instaname}`"
                class="account__nick"
                >{{ user.blogger_profile.instaname }}</a
              >
              <div v-if="subscribersCount" class="account__subsc">
                {{ subscribersCount }}
              </div>
              <div class="account__themes">Темы: {{ currentThemes }}</div>
            </div>
            <div class="account__header--right">
              <div class="coverage">
                <div v-if="user.blogger_profile.avg_coverage" class="coverage__block">
                  <div class="coverage___number">
                    {{ user.blogger_profile.avg_coverage.toLocaleString() }}
                  </div>
                  <div class="coverage__text">
                    Охват сторис <span class="coverage__text--opacity">в среднем</span>
                  </div>
                </div>
                <div v-if="user.blogger_profile.avg_coverage_posts" class="coverage__block">
                  <div class="coverage___number">
                    {{ user.blogger_profile.avg_coverage_posts.toLocaleString() }}
                  </div>
                  <div class="coverage__text">
                    Охват постов <span class="coverage__text--opacity">в среднем</span>
                  </div>
                </div>
              </div>
              <div class="audience stat-blckd">
                <div class="audience__title">
                  <div class="audience__title--main">Преобладающая статистика аудитории</div>
                  <!--div
                    v-if="user.blogger_profile.screens_last_updated"
                    class="audience__title--sec"
                  >
                    <a @click="showScreensModal = true" class="audience__title--screen"
                      >Скриншоты</a
                    >
                    статистики из Инстаграма от
                    {{ screensLastUpdated }}
                  </div>
                  <div-- class="audience__title--sec" v-else></div-->
                </div>
                <ul class="audience__info">
                  <li class="audience__block">
                    <div class="audience__category">Пол</div>
                    <div class="audience__text">
                      Женщины —
                      {{ user.blogger_profile.females_percent }}%
                    </div>
                  </li>
                  <li class="audience__block">
                    <div class="audience__category">Страна</div>
                    <div class="audience__text">
                      {{ user.blogger_profile.main_country.country.name }}
                      —
                      {{ user.blogger_profile.main_country.coverage }}%
                    </div>
                  </li>
                  <li class="audience__block">
                    <div class="audience__category">Дата обновления</div>
                    <div class="audience__text">
                      {{ dateAudienceUpdated }}
                    </div>
                  </li>
                  <li class="audience__block">
                    <div class="audience__category">Возраст</div>
                    <div class="audience__text">
                      {{ currentAudience }} —
                      {{ user.blogger_profile.main_audience.age_group_percent }}%
                    </div>
                  </li>
                  <li class="audience__block">
                    <div class="audience__category">Город</div>
                    <div class="audience__text" style="white-space: nowrap">
                      {{ user.blogger_profile.main_city.city.name }}
                      —
                      {{ user.blogger_profile.main_city.coverage }}%
                    </div>
                  </li>
                  <li class="audience__block">
                    <div class="audience__category">Средний рейтинг</div>
                    <div
                      v-if="
                        user.blogger_profile.reviews_stat &&
                        user.blogger_profile.reviews_stat.reviews_count
                      "
                      class="audience__text"
                    >
                      <img class="rev_star" src="@main/assets/img/svg/star.svg" alt="" />
                      {{
                        String(user.blogger_profile.reviews_stat.rate__avg.toFixed(1)).replace(
                          '.',
                          ','
                        )
                      }}
                      из
                      <a
                        @click="
                          document
                            .getElementById('card_reviews')
                            .scrollIntoView({ behavior: 'smooth' })
                        "
                        class="format__warn--link"
                        >{{ user.blogger_profile.reviews_stat.reviews_count }} отзывов</a
                      >
                    </div>
                    <div class="audience__text" v-else>Нет отзывов</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="account account--mobile">
          <div class="account__header mb-4">
            <div class="account__header--text fs-16">
              Статистика
              <a
                target="_blank"
                :href="`https://instagram.com/${user.blogger_profile.instaname}`"
                class="account__nick"
              >
                {{ user.blogger_profile.instaname }}</a
              >
              <div v-if="subscribersCount" class="account__subsc">
                {{ subscribersCount }}
              </div>
            </div>
          </div>
          <div class="account__header--bottom">
            <div class="coverage__mobile">
              <div
                v-if="user.blogger_profile.avg_coverage"
                class="coverage__mobile--wrap my-0 mb-1"
              >
                <div class="coverage__mobile--title">Охват сторис ≈</div>
                <div class="coverage__mobile--number">
                  {{ user.blogger_profile.avg_coverage.toLocaleString() }}
                </div>
              </div>
              <div
                v-if="user.blogger_profile.avg_coverage_posts"
                class="coverage__mobile--wrap my-0 mt-2"
              >
                <div class="coverage__mobile--title">Охват постов ≈</div>
                <div class="coverage__mobile--number">
                  {{ user.blogger_profile.avg_coverage_posts.toLocaleString() }}
                </div>
              </div>
            </div>
            <!--div class="account--mobile__rating">
              <div
                v-if="
                  user.blogger_profile.reviews_stat &&
                  user.blogger_profile.reviews_stat.reviews_count
                "
                class="account--mobile__rating--text"
              >
                {{ user.blogger_profile.reviews_stat.reviews_count }}
                отзывов <img class="rev_star" src="@main/assets/img/svg/star.svg" alt="" />
                {{ user.blogger_profile.reviews_stat.rate__avg.toFixed(1) }}
              </div>
              <div class="account--mobile__rating--text" v-else>Нет отзывов</div>
            </!--div-->
          </div>
          <div class="account__mobile--main">
            <div class="account__mobile--main--title">Преобладающая статистика аудитории</div>
            <div class="coverage__mobile" style="border-bottom: 2px solid #d5dfe4">
              <div class="coverage__mobile--wrap coverage__mobile--info">
                <div class="coverage__mobile--title coverage___mobile--stata">Страна</div>
                <div class="coverage__mobile--number coverage__mobile--margin-fz">
                  {{ user.blogger_profile.main_country.coverage }}%,
                  {{ user.blogger_profile.main_country.country.name }}
                </div>
              </div>
              <div class="coverage__mobile--wrap coverage__mobile--info">
                <div class="coverage__mobile--title coverage___mobile--stata">Город</div>
                <div
                  class="coverage__mobile--number coverage__mobile--margin-fz"
                  style="white-space: nowrap"
                >
                  {{ user.blogger_profile.main_city.coverage }}%,
                  {{ user.blogger_profile.main_city.city.name }}
                </div>
              </div>
              <div class="coverage__mobile--wrap coverage__mobile--info">
                <div class="coverage__mobile--title coverage___mobile--stata">Возраст</div>
                <div class="coverage__mobile--number coverage__mobile--margin-fz">
                  {{ user.blogger_profile.main_audience.age_group_percent }}%,
                  {{ currentAudience }}
                </div>
              </div>
              <div class="coverage__mobile--wrap coverage__mobile--info">
                <div class="coverage__mobile--title coverage___mobile--stata">Пол</div>
                <div class="coverage__mobile--number coverage__mobile--margin-fz">
                  {{ user.blogger_profile.females_percent }}%, Женщины
                </div>
              </div>
              <!--div
                v-if="user.blogger_profile.screens_last_updated"
                class="coverage__mobile-screens"
              >
                <div class="coverage__mobile--title">
                  Скриншоты статистики из Инстаграма от: {{ screensLastUpdated }}
                </div>
                <b-button
                  @click="showScreensModal = true"
                  class="coverage__mobile-screens-btn"
                  variant="outline-secondary"
                  size="md"
                  >Скриншоты</b-button
                >
              </!--div-->
              <template>
                <div
                  class="coverage__mobile--wrap big__stat-wrap mb-0 pb-0"
                  style="padding-top: 18px"
                >
                  <div style="display: contents" class="coverage__mobile--title">Темы:</div>
                  <div
                    style="display: contents"
                    class="coverage__mobile--number coverage__mobile--margin-fz statistic__big-text"
                  >
                    {{ currentThemes }}
                  </div>
                </div>
                <div
                  style="border: none !important"
                  class="coverage__mobile--wrap big__stat-wrap mt-1 pt-1"
                >
                  <div style="display: contents" class="coverage__mobile--title">
                    Дата обновления:
                  </div>
                  <div
                    style="display: contents"
                    class="coverage__mobile--number coverage__mobile--margin-fz statistic__big-text"
                  >
                    {{ dateAudienceUpdated }}
                  </div>
                </div>
                <b-button
                  @click="
                    () => {
                      $router.push('/fillstat/');
                      toggleStatisticCardModal({ open: false, context: { instaname: null } });
                    }
                  "
                  v-if="!me"
                  variant="outline-default"
                  class="w-100 mb-4 mt-3 py-2"
                  >🔥 Хочу такую же карточку на сайте</b-button
                >
              </template>
            </div>
          </div>
          <div class="account__footer"></div>
        </div>
        <!--div class="account__line account__line--mobile"></!--div-->
        <div class="format pt-4">
          <main class="format__main">
            <div class="format__title" style="font-size: 18px !important">
              Выберите форматы рекламы
            </div>
          </main>
        </div>
        <table class="table table__format d-none d-md-table mt-4">
          <tr class="table__header">
            <td class="table__header--td">Формат рекламы</td>
            <td class="table__header--td">Цена рекламы</td>
            <td class="table__header--td" style="width: 55%">Условия</td>
          </tr>
          <PrTypeDisplayable
            v-for="type in user.blogger_profile.pr_types"
            :key="`${type.id}-prTpTblMCrd`"
            :prType="type"
          />
        </table>
        <div class="notpr after768" v-if="user.blogger_profile.excluded_tags">
          <div class="notpr__text">
            Блогер не рекламирует: {{ user.blogger_profile.excluded_tags }}
          </div>
        </div>
        <div id="blogger-nopr" class="notpr till768 pt-2" v-if="user.blogger_profile.excluded_tags">
          <div class="notpr__text">
            <strong><p class="mt-2 mb-4">Блогер не рекламирует</p></strong>
            {{ user.blogger_profile.excluded_tags }}
          </div>
        </div>
        <prTypeTableMobile
          :hideClickButton="me"
          :prTypeClickHandler="prTypeClickHandler"
          :types="user.blogger_profile.pr_types"
        />
      </section>
      <h3
        id="card_reviews"
        class="statistics--card__title mobile_rev_card--title reviews__h mx-lg-3 text-center text-md-left"
      >
        Все отзывы на блогера
      </h3>
      <ReviewsTable
        :disclosed="true"
        ref="revTable"
        v-if="filterParams"
        :queryFields="[
          'ad_type',
          'arrival',
          'customer',
          'customer_kind',
          'customer_tags',
          'date',
          'id',
          'largest_reach',
          'paid_off',
          'price',
          'price_meta',
          'price_per_one',
          'rate',
          'show_text',
          'text_display',
          'type',
          'item',
          'liked',
          'liked_by_viewer',
        ]"
        :disableLikedBtn="true"
        :excludedFields="[
          'stat_btn',
          'advertiser',
          'index',
          'advertiser_blogger',
          'reviews_count_advertiser',
          'order_button',
        ]"
        v-model="filterParams"
      />
    </div>
    <modal-statistic
      v-if="user.blogger_profile.revealed_by_viewer || access || user.revealed"
      :show="showScreensModal"
      @hide="showScreensModal = false"
      :account="user"
    />
    <modal
      :show="showScreensModal"
      wrapClass="w-md-25 w-lg-25"
      @hide="showScreensModal = false"
      title="Недоступно"
      v-else
      ><div style="padding: 30px; font-size: 16px">
        Откройте карточку этого пользователя за проверки для просмотра скриншотов статистики!
      </div></modal
    >
  </div>
</template>
<script>
/* eslint-disable no-else-return, camelcase */
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';
import number from '@main/func/number';
import ModalStatistic from '@main/components/reusable/modals/ModalStatistic.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import utilsApi from '@main/api/utils';
import reviewsApi from '@main/api/reviews';
import EventBus from '../../../event-bus';
import PrTypeDisplayable from './PrTypeDisplayable.vue';
import instagram from '../../../api/instagram';
import lit from '../../../func/literal';
import ActionInfoModal from '../modals/ActionInfoModal.vue';
import bloggers from '../../../api/bloggers';
import ReviewsTable from '../../reviews_list/ReviewsTable.vue';
import prTypeTableMobile from './PrTypeTableMobile.vue';

export default {
  components: {
    PrTypeDisplayable,
    ActionInfoModal,
    ReviewsTable,
    ModalStatistic,
    Modal,
    prTypeTableMobile,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    prTypeClickHandler: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    graphql: null,
    graphError: false,
    hideCard: false,
    copyLink: false,
    showScreensModal: false,
    filterParams: null,
    bloggersCount: 0,
  }),
  computed: {
    ...mapState(['bloggerOptions', 'currentUser']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    dateAudienceUpdated() {
      try {
        return moment(this.user.blogger_profile.main_audience.last_updated).format('DD.MM.YYYY');
      } catch (e) {
        return 'нет даты';
      }
    },
    screensLastUpdated() {
      let res;
      try {
        res = moment(this.user.blogger_profile.screens_last_updated).format('DD.MM.YYYY');
        if (res === 'Invalid date') {
          return 'не указана';
        }
      } catch (e) {
        return '?';
      }
      return res;
    },
    currentAudience() {
      const { main_audience } = this.user.blogger_profile;
      const { choices } = this.options.audiences.child.children.age_group;
      if (main_audience.age_group === 999) {
        return 'Скрыт';
      }
      console.log(main_audience);
      const values = choices.filter((val) => val.value === main_audience.age_group);
      return values[0].display_name;
    },
    currentThemes() {
      return this.user.blogger_profile.tags.map((val) => val.name).join(', ');
    },
    subscribersCount() {
      if (!this.graphql) {
        return;
      }
      const { count } = this.graphql.user ? this.graphql.user.edge_followed_by : 0;
      if (!count) {
        return;
      }
      const calculated = number.abbreviateNumber(count, ['', ' тыс.', ' млн.']);
      const countstring =
        count > 1000
          ? `${String(calculated).replace('.', ',')} подписчиков`
          : `${count} ${lit.declension(count, 'подписчик')}`;
      return countstring;
    },
    me() {
      return !this.user.foreign;
    },
    link() {
      if (this.user.foreign) {
        return 'Скрыто';
      }
      return `${window.location.origin}/#/bloggers/${
        this.user.blogger_profile.instaname || this.user.blogger_profile.instaname_supposeable
      }/?access=${this.user.blogger_profile.da_token}`;
    },
    access() {
      return this.$route.query.access;
    },
  },
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    ...mapActions(['loadUser']),
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    ...mapMutations('reviewsList', ['setFilterParam', 'resetFilterParams']),
    async cardAction(act) {
      const response = await bloggers.cardAction(act);
      await this.loadUser();
    },
    async revealCard() {
      try {
        const res = await bloggers.revealCard(this.user.blogger_profile.instaname);
        if (res.success) {
          await this.loadUser();
          this.$emit('reload');
        }
      } catch (e) {
        if (e.response.status === 500) {
          this.showMessage({
            title: 'Ошибка! Повторите позднее',
          });
        } else if (e.response.status === 400) {
          this.showMessage({
            title: e.response.data.detail,
          });
        }
      }
    },
    async connectBot() {
      const token = await utilsApi.getBotConnectToken();
      window.open(`https://t.me/leadprbot?start=site-join---${token}`, '_blank');
    },
  },
  mounted() {
    bloggers
      .fnc({ actions: ['count'] }, { moderation_status: 2, instagram_verified: true, filled: true })
      .then((res) => {
        this.bloggersCount = res.result;
      });
    const iname = this.user.blogger_profile.instaname;
    instagram
      .getAccountGraphql(iname)
      .then((res) => {
        this.graphql = res.graphql;
      })
      .catch((e) => {
        if (!this.user.blogger_profile.instagram_id) {
          this.graphql = null;
          this.graphError = true;
        } else {
          instagram
            .getAccountInfo(this.user.blogger_profile.instagram_id)
            .then((res) => {
              this.graphql = res.graphql;
            })
            .catch((er) => {
              this.graphql = null;
              this.graphError = true;
            });
        }
      });
    this.filterParams = {
      ...reviewsApi.defaultFilterParams,
      executor: this.user.blogger_profile.instaname,
      page_size: 25,
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  font-family: Rubik !important;
}
.account__logo {
  min-width: 90px;
  height: 90px;
}
.card__section-stata {
  padding-bottom: 20px !important;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  border-radius: 10px;
  position: relative;
}

@media (max-width: 768px) {
  .card__wrap {
    width: 100% !important;
  }
  #card_reviews {
    background-color: white;
  }
  .container {
    padding: 15px !important;
    margin-bottom: -18px !important;
  }
  .card__section-stata {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #d5dfe4;
    padding-bottom: 20px !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .card__header {
    margin-bottom: 18px;
    padding-top: 6px;
    border-bottom: 1px solid #d5dfe4;
  }
  .card__wrap {
    margin: 0;
  }
  .statistics--card__title {
    font-size: 18px;
    margin-bottom: 4.5px;
    color: #192229;
  }
  ::v-deep .rectangle-block {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #d5dfe4 !important;
  }
  ::v-deep .full-table {
    padding: 0px !important;
    box-shadow: 0px 3px 10px 0px #0049811a;
    -webkit-box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  }
  ::v-deep .statistics--card__title {
    padding: 0px !important;
  }
}

@media (max-width: 330px) {
  .container {
    padding: 8px !important;
    padding-top: 12px !important;
  }
}

.container {
  width: 100%;
  max-width: 1140px;
}
.stat-card__btn {
  padding: 0.55rem 0.95rem !important;
}
.big__stat-wrap {
  align-items: flex-start !important;
  justify-content: space-between;
}

@media (max-width: 350px) {
  .big__stat-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .statistic__big-text {
    width: 100%;
    padding: 5px;
  }
  .account__header--bottom {
    flex-direction: column;
    align-items: flex-start;
  }
  .account--mobile__rating {
    border: none;
    font-size: 15px;
    width: 100% !important;
    padding: 5px;
    margin: 5px;
    margin-left: 0px;
    padding-left: 0px;
  }
}
.energy-pay--container__mobile {
  margin: -15px;
  margin-top: -19px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .energy-pay--container__mobile {
    display: none !important;
  }
  .table {
    margin-bottom: 0px !important;
  }
  .title_my-mobile {
    margin-left: 10px !important;
  }
}
@media (max-width: 520px) {
  .stat-card__btn {
    margin: 5px 10px !important;
  }
}
@media (max-width: 340px) {
  .stat-card__btn {
    font-size: 0.9rem !important;
  }
}
.stat-card__mobile_btn-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btn-mobile {
  padding: 0.45rem 0.4rem !important;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.reveal__card--block {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  background-color: #fffce3;
  height: 85px;
  justify-content: space-between;
  padding: 15px 25px;
  align-items: center;
}
@media (max-width: 768px) {
  .reveal__card--block {
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    border-radius: none !important;
  }
}
.reveal__card--button {
  padding: 8px 15px;
  font-size: 15px;
}
.reveal__card--text {
  max-width: 650px;
}
@media (max-width: 400px) {
  .bot-button {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1052px) {
  .bot-button {
    margin-top: 10px !important;
  }
  .statistics--card__title {
    margin-right: 3rem;
    min-width: 180px;
  }
}
@media (min-width: 768px) and (max-width: 945px) {
  .audience__block {
    min-width: 200px;
    margin-bottom: 2rem !important;
  }
  .coverage {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .account__header--left::after {
    left: 30% !important;
  }
  .stat-blckd {
    padding: 15px;
    border-radius: 20px;
    margin-left: 4.95rem;
    border: 1px solid lightgray;
  }
}
.stat-mycard {
  border: 1px solid #d5dfe4 !important;
  box-sizing: border-box !important;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1) !important;
  box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1) !important;
}

@media (max-width: 768px) {
  .energy-pay--container {
    display: none !important;
  }
  .dash-mobile {
    border-top: 2px dashed #d5dfe4 !important;
  }
  .mycard-header {
    border-bottom: none;
    margin-bottom: 3px !important;
  }
  .full-table {
    margin-bottom: 30px !important;
  }
  .mobile_rev_card--title {
    border: 1px solid #d5dfe4;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}
.redact-st {
  min-width: 153.64px;
}
@media (max-width: 374px) {
  .redact-st {
    min-width: 139.64px;
  }
}
</style>
