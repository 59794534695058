<template>
  <tr v-if="bloggerOptions" class="table__options">
    <td class="table__td">
      <div class="table__td-wrap">
        <div class="table__icon">
          <img :src="getIcon" alt="clock" class="table__icon--pic" />
        </div>
        <div class="table__td--text">{{ getCurrentPrType }}</div>
      </div>
    </td>
    <td v-if="!hidden" class="table__td table__price">{{ prType.uPrice }} ₽</td>
    <td v-else class="table__td table__price font-weight-bold">Скрыто</td>
    <td v-if="!hidden" class="table__td table__cond">
      Набор на {{ getCurrentPrMonth }}. {{ getCurrentOrdererType }}. {{ prType.content }}.
    </td>
    <td v-else class="table__td table__cond font-weight-bold">Скрыто</td>
  </tr>
</template>

<script>
import PrTypeDisplayableMixin from '@main/mixins/statistic/PrTypeDisplayableMixin.vue';

export default {
  mixins: [PrTypeDisplayableMixin],
};
</script>
