<template>
  <component
    v-bind="activeWindow.componentAttrs"
    :is="activeWindow.useComponent || 'Modal'"
    v-if="activeWindow"
    @hide="close"
    :show="!!activeWindow"
    :topLess="activeWindow.topLess === false ? activeWindow.topLess : true"
    :title="activeWindow.title"
    :displayClose="!activeWindow.backMode && !isMobile"
    :contentClass="activeWindow.contentClass || 'window-contnt'"
    :wrapClass="['modal-sm', activeWindow.backMode ? 'modal-backed' : '']"
  >
    <h4
      v-bind="activeWindow.titleAttrs"
      :class="`text-left mb-3 w-100 ${
        activeWindow.titleAttrs ? activeWindow.titleAttrs.class : ''
      }`"
      v-if="activeWindow.title"
    >
      {{ activeWindow.title }}
    </h4>
    <div class="w-100" v-if="activeWindow.contentComponent" v-bind="activeWindow.ccWrapperAttrs">
      <component
        ref="cc"
        v-bind="activeWindow.ccAttrs"
        :is="activeWindow.contentComponent"
      ></component>
    </div>
    <div v-else class="d-flex flex-column align-items-center" v-bind="activeWindow.textBlockAttrs">
      <img v-if="activeWindow.iconPath" class="window-icon" :src="activeWindow.iconPath" />
      <h2 v-if="!activeWindow.html" class="window-text">{{ activeWindow.text }}</h2>
      <p
        style="padding-right: 2px; white-space: pre-line; max-height: 486px; overflow: auto"
        v-html="activeWindow.html"
        v-else
      ></p>
    </div>
    <div class="video-container py-3" v-if="activeWindow.video">
      <b-embed v-bind="activeWindow.video.attrs"></b-embed>
    </div>
    <div
      v-bind="activeWindow.footer ? activeWindow.footer.attrs : null"
      class="footer__buttons mt-1 d-flex flex-wrap justify-content-start justify-content-md-center pb-4"
      v-if="activeWindow.buttons"
    >
      <b-button
        v-bind="btn.attrs"
        class="mx-4 mt-3"
        :class="
          activeWindow.buttonClass || 'footer__button-notification-window mt-3 w-100 w-xsm-auto'
        "
        @click="btn.handler ? btn.handler($refs.cc) : null"
        :key="btn.text"
        v-for="btn in activeWindow.buttons || []"
      >
        {{ btn.text }}
      </b-button>
    </div>
  </component>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import Modal from '@main/components/reusable/modals/Modal.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  data: () => ({
    isMobile,
  }),
  components: {
    Modal,
  },
  computed: {
    ...mapState('notifications', ['activeWindow']),
  },
  methods: {
    ...mapMutations('notifications', ['setWindow']),
    ...mapMutations(['setBackMode']),
    close() {
      this.setWindow(null);
    },
  },
  mounted() {
    if (this.activeWindow.backMode && this.isMobile) {
      this.setBackMode({
        // eslint-disable-next-line func-names, object-shorthand
        handler: this.close,
      });
    }
  },
};
</script>

<style>
@media (max-width: 768px) {
  .modal-backed {
    padding-top: 80px !important;
  }
}
</style>

<style lang="scss" scoped>
.window-text {
  text-align: center;
  margin-top: 25px;
}
.window-icon {
  width: 70px;
  height: 70px;
}
.footer__button-notification-window {
}
.video-container {
  width: 100%;
}
@media (min-width: 768px) {
  .video-container {
    width: 500px;
  }
}
</style>
