import apiService from './index';

export default {
  async getFooterLinks() {
    try {
      const response = await apiService.get('/footer-links/');
      return response;
    } catch (e) {
      return e;
    }
  },
  async getTags() {
    const response = await apiService.get('/api/core/data/themes/');
    return response.results;
  },
  async getAccountGraphql(login) {
    return new Promise((resolve, reject) => {
      const URL = `https://www.instagram.com/${login}/?__a=1`;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', URL, true);
      xhr.onload = () => {
        try {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(new Error(xhr.statusText));
          }
        } catch (e) {
          console.log(e);
        }
      };
      xhr.onerror = () => {
        reject(new Error(xhr.statusText));
      };
      xhr.send();
    });
  },
  async getBotConnectToken() {
    const response = await apiService.get('/api/statistic/bot-connect-token/');
    return response.token;
  },
};
