<template>
  <b-modal
    ref="modal"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    @hide="$emit('input', false)"
    @ok="() => $emit('ok')"
    modal-class="side-modal"
  >
    <slot></slot>
  </b-modal>
</template>

<style scoped lang="scss">
::v-deep .side-modal {
  background: rgba(0, 0, 0, 0.3);
  padding-left: 0px !important;
}

::v-deep .side-modal .modal-dialog {
  margin: 0;
}

::v-deep .modal-content {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

::v-deep .side-modal .modal-content {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  max-width: 320px;
}

::v-deep .side-modal .review-wrapper {
  box-shadow: none !important;
}
</style>

<script>
export default {
  props: ['value'],
  watch: {
    value(v) {
      if (v) this.$refs.modal.show();
      else this.$refs.modal.hide();
    },
  },
  mounted() {
    if (this.value) this.$refs.modal.show();
  },
};
</script>
