<template>
  <div
    class="review col col-sm-4 col-md-6 col-lg-4"
    :class="{ 'review-yellow': !!review.referencedBy }"
  >
    <div class="review-top-info">{{ review.date }}, № {{ review.id }}</div>
    <div
      v-if="!review.referencedBy"
      @click="() => $emit('like', review)"
      style="top: 35px; right: 10px; z-index: 2"
      class="position-absolute d-flex align-items-center"
    >
      <p class="gray-text mr-1 fs-12">{{ review.liked }}</p>
      <img
        style="width: 18px; height: 18px"
        v-if="!review.liked_by_viewer"
        class="ml-1"
        src="@main/assets/img/svg/like-gray-outline.svg"
        alt=""
      />
      <img
        style="width: 18px; height: 18px"
        class="ml-1"
        v-else
        src="@main/assets/img/svg/like.svg"
        alt=""
      />
    </div>
    <table class="review-table">
      <tbody>
        <tr
          :key="field.key"
          :class="{ 'review-tr-empty': field.empty }"
          v-for="field in showedFields"
          :style="field ? null : 'display: none'"
        >
          <template v-if="field.type === 'data' || !field.type">
            <th>
              {{ field.label }}
            </th>
            <td>
              <template v-if="field.key === 'rate'">
                {{ review.rate }} из 10 <b-icon-star-fill style="color: #fbc400" />
              </template>
              <template v-else-if="['advertiser', 'customer'].indexOf(field.key) !== -1">
                <a
                  class="link"
                  target="_blank"
                  :href="`https://instagram.com/${review[field.key]}`"
                  >{{ review[field.key] }}</a
                >
              </template>
              <template v-else-if="field.key === 'arrival'">
                <div class="d-flex flex-row align-items-center">
                  {{ review[field.key] }}
                  <span style="font-size: 12px; height: 16px" class="ml-1 gray-text">
                    {{ declSubscribers(Number(review[field.key])) }}</span
                  >
                </div>
              </template>
              <template v-else>
                {{ review[field.key] }}
              </template>
            </td>
          </template>
          <template v-else-if="field.type === 'button'">
            <b-button @click="field.handler" :variant="field.variant" :class="field.class">{{
              field.label
            }}</b-button>
          </template>
          <template v-else-if="field.type === 'inline-data'">
            <p style="text-align: left; white-space: pre-line">
              <span>{{ field.label }}: </span> <span v-if="field.html" v-html="field.text"></span>
              <span v-else>{{ field.text }}</span>
            </p>
          </template>
        </tr>
      </tbody>
    </table>
    <div style="line-height: 18.9px; font-size: 14px" v-if="isDetail">
      {{ review.text_display }}
    </div>
    <b-button
      v-if="!(filterParams.mode === 'me') && review.reviews_count_advertiser > 1 && !disclosed"
      variant="link"
      class="link d-flex justify-content-between w-75 py-1 mt-2"
      :class="{ rotate: isDetail }"
      @click="isDetail = !isDetail"
    >
      {{ isDetail ? 'Свернуть отзыв' : 'Раскрыть отзыв полностью' }}
    </b-button>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <b-button
        :disabled="loading.includes(review.id)"
        v-if="
          !review.referencedBy &&
          review.reviews_count_advertiser > 1 &&
          !(filterParams.mode === 'me')
        "
        class="py-2 rev-more__mobile mt-3 mr-auto"
        variant="outline-default"
      >
        <div
          class="rev-more__mobile__text"
          style="font-size: 14px; line-height: 16.5px"
          @click="$emit('show-more', review.id)"
          v-if="!review.referencedBy && !review.showMore && review.reviews_count_advertiser > 1"
        >
          {{ moreText }}<br />
          <span style="font-size: 13px" class="gray-text">на блогера</span>
        </div>
        <div
          class="rev-more__mobile__text"
          style="font-size: 14px; line-height: 16.5px"
          @click="$emit('hide-more', review.id)"
          v-if="review.showMore"
        >
          Свернуть отзывы<br />
          <span class="gray-text">на блогера</span>
        </div>
      </b-button>
      <b-button
        v-else-if="!(filterParams.mode === 'me') && !disclosed"
        variant="link"
        style="max-width: 110px"
        class="link d-flex justify-content-between py-1 mt-2 text-left rev-more__mobile__text"
        :class="{ rotate: isDetail }"
        @click="isDetail = !isDetail"
      >
        {{ isDetail ? 'Свернуть отзыв' : 'Раскрыть отзыв полностью' }}
      </b-button>
      <template
        v-if="
          !review.referencedBy &&
          !(excludedFields && excludedFields.includes('order_button')) &&
          !(filterParams.mode === 'me')
        "
      >
        <b-button
          style="border-color: #00d463"
          v-if="ordered"
          @click="
            {
              $router.push('/promobot/orders/cart/');
              setWindow(null);
            }
          "
          variant="outline-default"
          class="py-2 mt-3 px-3 ml-auto"
          ><div class="rev-more__mobile__text" style="font-size: 14px; line-height: 16.5px">
            <img src="@main/assets/img/svg/green-check.svg" class="d-inline-block mr-1" />
            В корзине<br />
            <span style="font-size: 13px" class="gray-text">Отправить заявку</span>
          </div></b-button
        >
        <b-button
          v-else
          :style="review.advertiser_blogger ? 'border: 1px solid #f9d451' : ''"
          @click="handleGatheringRequest"
          :variant="'outline-default'"
          class="py-2 mt-3 ml-auto"
        >
          <div class="rev-more__mobile__text" style="font-size: 14px; line-height: 16.5px">
            Отправить запрос<br />
            <span style="font-size: 13px" class="gray-text ws-nowrap">на рекламу на сайте</span>
          </div></b-button
        ></template
      >
    </div>
    <p
      v-if="!review.advertiser_blogger && review.orders_count && !review.referencedBy"
      class="mt-3"
      style="
        background-color: #ecf5fe;
        font-size: 11px;
        border-radius: 3px;
        line-height: 15px;
        padding: 4px 6px;
      "
    >
      Блогер ещё не зарегистрировал свою карточку на Easyprbot.
      <strong
        >{{ review.orders_count }} {{ literal.declension(review.orders_count, 'заказчик') }}</strong
      >
      оставил{{ String(review.orders_count).endsWith('1') ? '' : 'и' }} запрос на рекламу и ожида{{
        String(review.orders_count).endsWith('1') ? 'ет' : 'ют'
      }}
      его регистрации
    </p>
    <b-button
      @click="() => $emit('del', review.id)"
      variant="outline-default"
      class="manip__btn-rev mt-2"
      v-if="filterParams.mode === 'me' && isDetail"
    >
      Удалить отзыв
    </b-button>
  </div>
</template>

<script>
import literal from '@main/func/literal';
import bot from '@main/api/bot';
import wrappers from '@main/func/wrappers';
import AdvertisingApplicationForm from '@main/components/forms/AdvertisingApplicationForm.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { isMobile } from 'mobile-device-detect';

export default {
  props: ['review', 'loading', 'filterParams', 'excludedFields', 'disclosed'],
  data() {
    return {
      isMobile,
      openText: false,
      isDetail: false,
      mode: 'all',
    };
  },
  computed: {
    ...mapState(['tags', 'userCart', 'currentUser']),
    ...mapState('reviewsModeration', ['refuseReasons']),
    showedFields() {
      return this.fields.filter((field) =>
        field ? !field.ifDetail || (field.ifDetail && this.isDetail) : null
      );
    },
    fields() {
      let fieldArray = [
        this.review.customer_kind === 'shop'
          ? { key: 'paid_off', label: 'Реклама окупилась' }
          : null,
        { key: 'price_per_one', label: 'Цена за подписчика' },
        { key: 'arrival', label: 'Приход' },
        { key: 'price', label: 'Цена за рекламу' },
        this.review.largest_reach ? { key: 'largest_reach', label: 'Охваты из рекламы' } : null,
        { key: 'advertiser', label: 'Аккаунт исполнителя' },
        { key: 'rate', label: 'Оценка' },
        { key: 'empty2', empty: true, ifDetail: true },
        { key: 'ad_type', label: 'Формат рекламы', ifDetail: true },
        { key: 'customer', label: 'Аккаунт заказчика', ifDetail: true },
        this.filterParams.customer_kind === 'blogger'
          ? { key: 'customer_tags', label: 'Темы аккаунта', ifDetail: true }
          : { key: 'item', label: 'Товар или услуга' },
        this.filterParams.mode === 'me' ? { key: 'emptyme', empty: true, ifDetail: true } : null,
        this.filterParams.mode === 'me'
          ? { label: 'Статус', type: 'inline-data', html: true, text: this.review.processed }
          : null,
        this.filterParams.mode === 'me' && !this.review.processed && !this.review.accepted
          ? { label: 'Причина', type: 'inline-data', text: this.reviewRefuseReason }
          : null,
        this.filterParams.mode === 'me' && !this.review.accepted && this.review.processed
          ? {
              type: 'button',
              class: 'w-100 mb-4 mt-2 manip__btn-rev',
              variant: 'outline-default',
              label: 'Редактировать и отправить заново',
              handler: this.editReview,
            }
          : { key: 'empty3', empty: true, ifDetail: true },

        this.review.text_display ? { label: 'Текст отзыва:', ifDetail: true } : null,
      ];
      fieldArray = this.lodash.compact(fieldArray);
      if (this.excludedFields) {
        return fieldArray.filter((field) => !this.excludedFields.includes(field.key));
      }
      return fieldArray;
    },
    moreText() {
      return `Еще ${this.review.reviews_count_advertiser - 1} ${literal.declension(
        this.review.reviews_count_advertiser - 1,
        'отзыв'
      )}`;
    },
    reviewRefuseReason() {
      const reasons = this.refuseReasons.filter((reason) => {
        return this.review.refuse_reasons.includes(reason.id);
      });
      let reasonText = reasons.map((reason) => reason.text).join('\n\n');
      if (this.review.refuse_reason) {
        reasonText += `\nКомментарий модератора: ${this.review.refuse_reason}`;
      }
      if (!reasonText) {
        return 'не указана.';
      }
      return reasonText;
    },
    ordered() {
      if (!this.userCart) {
        return false;
      }
      return this.userCart.reviews.includes(this.review.id);
    },
  },
  methods: {
    ...mapActions(['updateUserCart']),
    ...mapMutations('notifications', ['setWindow']),
    declSubscribers(val) {
      return literal.declension(val, 'подписчик');
    },
    editReview() {
      this.$emit('edit', this.review.id);
    },
    getInstagramUrl(i) {
      return `https://www.instagram.com/${i}/`;
    },
    toggleText() {
      this.openText = !this.openText;
    },
    async handleGatheringRequest() {
      wrappers.requiresAuth(async () => {
        if (this.review.advertiser_blogger) {
          await this.$emit('show-stat', String(this.review.advertiser));
          setTimeout(() => {
            document.getElementById('blogger-nopr').scrollIntoView({ behavior: 'smooth' });
          }, 1000);
          return;
        }
        await this.updateUserCart({
          ...this.currentUser.preorders,
          reviews: this.userCart.reviews.concat(this.review.id),
        });
      });
    },
  },
  mounted() {
    if (this.filterParams.mode === 'me' || this.disclosed) {
      this.isDetail = true;
    }
  },
};
</script>

<style scope>
.review-table {
  width: 100%;
}

.review {
  padding: 37px 15px 15px;
  border-bottom: 1px solid #d5dfe4;
  position: relative;
  flex-basis: 1 !important;
  min-width: 270px;
}

.review:nth-child(2n) {
  background-color: #f8f8f8;
}

.review-yellow {
  background-color: #fffcec !important;
}

.review-yellow:nth-child(2n + 1) {
  background-color: #fffce3 !important;
}

.review-top-info {
  position: absolute;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  color: #6a7b83;
  top: 11px;
  right: 10px;
}

.review-table tr {
  min-height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.review-table th {
  color: #59676e;
  max-width: 148px;
}

.review-table td {
  width: 142px;
}

.link {
  width: 100% !important  ;
}

a {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden;
  text-overflow: ellipsis;
}

.review-tr-empty {
  height: 15px !important;
  min-height: 15px !important;
}

.show-details {
  width: 130px;
  position: relative;
}

.show-details::before {
  content: '';
  position: absolute;
  right: 7px;
  -webkit-transform-origin: 50% 25%;
  transform-origin: 50% 25%;
  top: 11px;
  border: 5px solid transparent;
  border-top: 5px solid #6a7b83;
}

.show-details.rotate::before {
  transform: rotate(180deg);
}

.show-details::after {
  content: '';
  width: 24px;
  height: 24px;
  border: 1px solid #d5dfe4;
  display: block;
  background-color: #fff;
  border-radius: 5px;
}

.review-top-info {
  font-size: 12.5px;
}
.rev-more__mobile {
  min-width: 150px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
@media (max-width: 350px) {
  .review-top-info {
    font-size: 11px;
  }
  .rev-more__mobile {
    font-size: 11px !important;
    max-width: 135px !important;
    min-width: 110px !important;
  }
  .rev-more__mobile__text {
    font-size: 12.5px !important;
  }
  .rev-more__mobile__text > span {
    font-size: 11.4px !important;
  }
  .review-table td {
    padding-left: 18px;
    width: 130px;
  }
}
.manip__btn-rev {
  padding: 8px 10px !important;
  font-size: 13px !important;
}
</style>
