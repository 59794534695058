import Cookies from 'js-cookie';
import bot from '@main/api/bot';
import auth from '../api/auth';
import ENUM from '../enums';
import utils from '../api/utils';
import bloggers from '../api/bloggers';
import info from '../api/info';

export default {
  async loadUser(context) {
    const user = await auth.getMe();
    if (user instanceof Error) context.commit('setCurrentUser', null);
    else context.commit('setCurrentUser', user);
  },
  async loadUserCart(context) {
    const response = await bot.userPreorder.get();
    context.commit('setUserCart', response);
  },
  async updateUserCart(context, data) {
    const response = await bot.userPreorder.update(data);
    context.commit('setUserCart', response.data);
  },
  async loadFooterLinks(context) {
    const links = await utils.getFooterLinks();
    context.commit('setFooterLinks', links);
  },
  async loadTags(context) {
    const tags = await info.getThemes();
    context.commit(
      'setTags',
      tags.filter((val) => val.id < 1000000)
    );
  },
  async loadCities(context) {
    const cities = await info.getCities();
    context.commit('setCities', cities);
  },
  async loadBloggerOptions(context) {
    const options = await bloggers.getBloggerOptions();
    context.commit('setBloggerOptions', options);
  },
  async loadCountries(context) {
    const countries = await info.getCountries();
    context.commit('setCountries', countries);
  },
  async login(context, token) {
    // localstorage - для обратной совместимости со старым сайтом
    localStorage.setItem('token', token);
    Cookies.set('jwt_access', token);
    context.dispatch('loadUser');
  },
  async logout(context) {
    localStorage.removeItem('token');
    Cookies.remove('jwt_access');
    Cookies.remove('jwt_refresh');
    context.commit('setCurrentUser', null);
    window.location.reload();
  },
  async apiLoaded(context) {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        if (context.state.apiState === ENUM.LOADED) {
          clearInterval(intervalId);
          resolve();
        } else if (context.state.apiState === ENUM.ERROR) {
          clearInterval(intervalId);
          reject();
        }
      }, 500);
    });
  },
};
