export default {
  abbreviateNumber(number, suffixes) {
    const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    // eslint-disable-next-line no-unneeded-ternary
    const SUFFIXES = suffixes ? suffixes : SI_SYMBOL;
    // what tier? (determines SI symbol)
    // eslint-disable-next-line no-bitwise
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    // eslint-disable-next-line eqeqeq
    if (tier == 0) return number;

    // get suffix and determine scale
    const suffix = SUFFIXES[tier];
    // eslint-disable-next-line no-restricted-properties
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  },
  range(num) {
    return [...Array(num).keys()].map((n) => n);
  },
};
