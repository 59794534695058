import apiService from './index';

const JOIN_LINKS_URL = `/api/notifications/join-links/`;
const NOTIFICATIONS_BOT_USERS = `/api/notifications/bot-user/`;
const NOTIFICATIONS_SETTINGS = `/api/notifications/settings/`;

const CHECK_MESSAGES_URL = `/api/notify/messages/latest/`;
const READ_MESSAGE_URL = `/api/notify/messages/read/`;

export async function getJoinLinks() {
  try {
    const response = await apiService.get(JOIN_LINKS_URL);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getBotUsers() {
  try {
    const response = await apiService.get(NOTIFICATIONS_BOT_USERS);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getNotificationsSettings() {
  try {
    const response = await apiService.get(NOTIFICATIONS_SETTINGS);
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateNotificationsSettings(data) {
  try {
    const response = await apiService.put(NOTIFICATIONS_SETTINGS, data);
    return response;
  } catch (e) {
    return e;
  }
}

/**
 * Получение новых сообщений
 * @param {number} lastId Id последнего сообщения
 */
export async function CheckMessages(lastId) {
  const response = await apiService.get(`${CHECK_MESSAGES_URL}?last_id=${lastId}`).catch(() => {});
  return response;
}

/**
 * Пометка сообщения, как прочитанного
 * @param {number} messageId Id сообщения
 */
export async function ReadMessage(messageId) {
  const response = await apiService.post(READ_MESSAGE_URL, { message_id: messageId });
  return response;
}
