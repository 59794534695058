<template>
  <Modal
    title="Редактирование фильтрации спама"
    :wrapClass="'mw-100 mh-50'"
    :show="changeBotSettingsModal"
    @hide="toggleChangeBotSettingsModal(false)"
  >
    <div style="min-width: 300px; overflow-y: auto" class="m-4" v-if="setting">
      <div>
        <p class="mb-1">Фильтровать:</p>
        <b-textarea
          label="wdaw"
          style="min-width: 300px; min-height: 350px"
          v-model="setting.value"
        >
        </b-textarea>
        <p class="mb-1 mt-3">Исключения:</p>
        <b-textarea
          class=""
          v-model="setting.exceptions"
          style="min-width: 300px; min-height: 350px"
        ></b-textarea>
        <div class="mt-3 d-flex justify-content-between">
          <b-button variant="outline-default" @click="saveSetting">Сохранить</b-button>
          <b-button variant="outline-default" @click="disableSpam">Отключить фильтрацию</b-button>
        </div>
      </div>
    </div>
    <Loader v-else />
  </Modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Modal from '@main/components/reusable/modals/Modal.vue';
import Loader from '@main/components/Loader.vue';
import botApi from '@main/api/bot';

export default {
  components: {
    Modal,
    Loader,
  },
  data: () => ({
    setting: null,
    settingName: 'spam_keywords',
  }),
  props: {
    show: Boolean,
  },
  computed: {
    ...mapState('popups', ['changeBotSettingsModal']),
  },
  methods: {
    ...mapMutations('popups', ['toggleChangeBotSettingsModal']),
    async saveSetting() {
      const res = await botApi.settings.partial_update(this.settingName, this.setting);
      console.log(res);
    },
    async disableSpam() {
      const res = await botApi.groups.partial_update('@instalogiya_chat', { delete_spam: false });
    },
  },
  async mounted() {
    const res = await botApi.settings.retrieve(this.settingName);
    this.setting = res;
  },
};
</script>
