import Vue from 'vue';
import moderationApi from '../../api/reviews-moderation';

export default {
  namespaced: true,
  state: {
    refuseReasons: [],
    showSolutionPopup: false,
    solutionPopupMode: null,
    currentSolutionReview: null,
    reviewsPopupShow: false,
    reviewsPopupTitle: null,
    reviewsPopupList: [],
  },
  mutations: {
    setRefuseReasons(state, reasons) {
      state.refuseReasons = reasons;
    },
    showSolutionPopup(state) {
      state.showSolutionPopup = true;
    },
    hideSolutionPopup(state) {
      state.showSolutionPopup = false;
    },
    setSolutionPopupMode(state, mode) {
      state.solutionPopupMode = mode;
    },
    setCurrentSolutionReview(state, review) {
      state.currentSolutionReview = review;
    },
    showReviewsPopup(state) {
      state.reviewsPopupShow = true;
    },
    hideReviewsPopup(state) {
      state.reviewsPopupShow = false;
    },
    setReviewsPopupList(state, reviews) {
      state.reviewsPopupList = reviews;
    },
    setReviewsPopupTitle(state, title) {
      state.reviewsPopupTitle = title;
    },
  },
  actions: {
    async loadRefuseReasons(context) {
      const reasons = await moderationApi.getRefuseReasons();
      context.commit('setRefuseReasons', reasons);
    },
    async loadReviewsPopupList(context, payload) {
      context.commit('setReviewsPopupList', null);
      const { results } = await moderationApi.list(payload);
      context.commit('setReviewsPopupList', results);
    },
  },
};
