<template>
  <sliding-pagination v-bind="getAttrs()" v-on="$listeners" class="c-sliding-pagination__wrapper">
    <span slot="previous-page">Назад</span>
    <span slot="next-page">Вперёд</span>
  </sliding-pagination>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import SlidingPagination from 'vue-sliding-pagination';

export default {
  components: { SlidingPagination },
  methods: {
    getAttrs() {
      const attrs = this.$attrs;
      if (isMobile) {
        attrs.slidingWindowSize = 1;
        attrs.slidingEndingSize = 1;
        attrs.nonSlidingSize = 3;
      }
      return attrs;
    },
  },
};
</script>

<style>
@import '~vue-sliding-pagination/dist/style/vue-sliding-pagination.css';

.c-sliding-pagination__wrapper {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.c-sliding-pagination__page {
  padding: 0.6em;
  display: block;
}

.c-sliding-pagination__list-element {
  background-color: #fff;
  border: 1px solid #e4eff4;
  color: #6a7b83;
  border-right: none;
  margin-right: 0;
  border-radius: 0;
  padding: 0;
}

.c-sliding-pagination__list-element:hover {
  background-color: #3897f0c2;
}

.c-sliding-pagination__list-element:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.c-sliding-pagination__list-element:last-child {
  border-right: 1px solid #e4eff4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.c-sliding-pagination__list-element--active {
  background-color: #3897f0;
  border: 1px solid #3897f0;
  color: #fff;
}
</style>
