<template>
  <Modal :topLess="true" :show="show" @hide="() => $emit('close')">
    <div class="modal-header__stat modal-header--placed">
      <svg class="modal-icon">
        <use xlink:href="../../../assets/img/icons/sprite.svg#okay"></use>
      </svg>
    </div>
    <div class="modal-body__stat modal-body--placed">
      <slot name="body"></slot>
    </div>
  </Modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
  },
  watch: {
    show(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.show {
  display: block !important;
}
.copy-cntnt {
  max-width: 300px !important;
}
.copy-close {
  position: absolute;
  top: 3%;
  right: 4%;
}
</style>
