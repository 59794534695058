import moment from 'moment';
/* eslint-disable  func-names */

export default {
  guidGenerator() {
    const S4 = () => {
      return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
    };
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  },
  requestDebounce(func, hash, minutes, ...args) {
    // eslint-disable-next-line func-names
    const wrapper = function () {
      const localHash = localStorage.getItem(hash);
      const lastUpd = moment(localHash);
      if (!localHash || +lastUpd.add(minutes, 'm') <= +new Date()) {
        func.apply(this, ...args);
        console.log(localStorage.getItem('awdaw'));
        localStorage.setItem(hash, new Date().toLocaleDateString());
      }
    };
    return wrapper;
  },
};
