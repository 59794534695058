<template>
  <div :class="[wrapper, popupMode ? 'popup-mode' : '']">
    <div v-if="apiState === 3" :class="containerClass">
      <SignIn />
      <SettingsChangeModal v-if="changeBotSettingsModal" />
      <ChangePasswordModal v-if="changePasswordModal.open" />
      <EnergyPaymentModal v-if="showEnergyPriceModal" />
      <StatisticCardModal v-if="statisticCardModal.open" />
      <InstagramVerificationModal v-if="instagramVerificationModal.open" />
      <MainSubscriptionPaymentModal />
      <Header v-if="!popupMode" />
      <PopupContainer />
      <SideNotificationContainer />
      <NotificationWindow v-if="activeWindow" />
      <div id="app" :class="`container--height ${layout}`">
        <router-view v-if="apiState === 3" />
      </div>
    </div>
    <div v-else style="height: 100vh"><Loader /></div>
    <Footer v-if="!popupMode" />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ENUM from '@main/enums.js';
import Header from '@main/components/Header.vue';
import Footer from '@main/components/Footer.vue';
import SignIn from '@main/components/SignIn.vue';
import StatisticCardModal from '@main/components/reusable/modals/StatisticCardModal.vue';
import ChangePasswordModal from '@main/components/reusable/modals/ChangePasswordModal.vue';
import PopupContainer from '@main/components/notifications/PopupContainer.vue';
import NotificationWindow from '@main/components/notifications/NotificationWindow.vue';
import SideNotificationContainer from '@main/components/notifications/SideNotificationContainer.vue';
import SettingsChangeModal from '@main/components/reusable/modals/SettingsChangeModal.vue';
import MainSubscriptionPaymentModal from '@main/components/reusable/modals/MainSubscriptionPaymentModal.vue';
import InstagramVerificationModal from '@main/components/reusable/modals/InstagramVerificationModal.vue';
import Loader from '@main/components/Loader.vue';
import auth from '@main/api/auth';
import literal from '@main/func/literal';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import EnergyPaymentModal from './components/reusable/modals/EnergyPaymentModal.vue';

export default {
  name: 'App',
  components: {
    Header,
    SignIn,
    Footer,
    PopupContainer,
    Loader,
    NotificationWindow,
    SideNotificationContainer,
    ChangePasswordModal,
    EnergyPaymentModal,
    SettingsChangeModal,
    StatisticCardModal,
    MainSubscriptionPaymentModal,
    InstagramVerificationModal,
  },
  computed: {
    ...mapState('payments', ['showEnergyPriceModal']),
    ...mapState(['currentUser', 'apiState']),
    ...mapState('notifications', ['activeWindow']),
    ...mapGetters(['hasTokens']),
    ...mapState('popups', [
      'changePasswordModal',
      'changeBotSettingsModal',
      'statisticCardModal',
      'instagramVerificationModal',
    ]),
    layout() {
      if (this.$route.meta.layout !== undefined) {
        return this.$route.meta.layout;
      }
      return 'container--other';
    },
    wrapper() {
      if (this.$route.meta.wrapper !== undefined) {
        return this.$route.meta.wrapper;
      }
      return '';
    },
    containerClass() {
      if (this.$route.meta.containerClass !== undefined) {
        return this.$route.meta.containerClass;
      }
      return '';
    },
    popupMode() {
      return this.$route.query.popup === '1';
    },
  },
  methods: {
    ...mapActions([
      'loadUser',
      'loadFooterLinks',
      'loadTags',
      'loadCountries',
      'loadBloggerOptions',
      'loadUserCart',
    ]),
    ...mapActions('reviewsModeration', ['loadRefuseReasons']),
    ...mapMutations(['setApiState', 'setBackMode']),
    ...mapMutations('notifications', [
      'showMessage',
      'setWindow',
      'addSideNotification',
      'readSideNotification',
    ]),
    ...mapMutations('popups', ['toggleChangePasswordModal']),
    async initApp() {
      this.setApiState(ENUM.LOADING);
      try {
        if (this.hasTokens) {
          await this.loadUser();
        }
        await this.loadBloggerOptions();
        await this.loadFooterLinks();
        await this.loadTags();
        await this.loadCountries();
        await this.loadRefuseReasons();
        await this.loadUserCart();
      } catch (e) {
        if (e.response && e.response.status >= 500) {
          this.setApiState(ENUM.ERROR);
          this.$router.push({ name: '500' });
        }
      }
      this.setApiState(ENUM.LOADED);
      this.initWarnings();
      setTimeout(this.initVuexQuery, 1700);
    },
    initVuexQuery() {
      const { vq } = this.$route.query;
      if (vq) {
        const data = JSON.parse(vq);
        this.$store[data.type](
          // eslint-disable-next-line prefer-template
          `${data.location ? data.location + '/' : ''}${data.name}`,
          data.value
        );
        if (data.next) {
          this.$router.push({ query: { next: data.next, vq: '' } });
        }
      }
    },
    initWarnings() {
      if (localStorage.getItem('cookiePolicy') === 'disagreed') {
        // eslint-disable-next-line no-restricted-properties
        if (!document.__defineGetter__) {
          Object.defineProperty(document, 'cookie', {
            get() {
              return '';
            },
            set() {
              return true;
            },
          });
        } else {
          // eslint-disable-next-line no-restricted-properties
          document.__defineGetter__('cookie', function () {
            return '';
          });
          // eslint-disable-next-line no-restricted-properties
          document.__defineSetter__('cookie', function () {});
        }
        setInterval(() => {
          // eslint-disable-next-line func-names
          console.log(document.cookie);
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              // eslint-disable-next-line prefer-template
              .replace(
                /=.*/,
                // eslint-disable-next-line prefer-template
                '=;expires=' + new Date().toUTCString() + ';path=/'
              );
          });
        }, 100);
      }
      if (
        this.currentUser &&
        this.currentUser.blogger_profile &&
        !this.currentUser.blogger_profile.filled
      ) {
        this.showMessage({
          title: `<a class="" href="/#/fillstat/">Заполните до конца вашу карточку блогера на сайте</a>`,
          icon: 2,
        });
      }
      if (!localStorage.getItem('cookiePolicy')) {
        if (
          !this.currentUser ||
          !(this.currentUser && this.currentUser.profile.cookie_policy_agree)
        ) {
          this.addSideNotification({
            message: `Мы используем куки, чтобы вы могли полноценно пользоваться сайтом. <a style='color: white; white-space: break-spaces; text-decoration: underline' href='/cookie_policy' target='_blank'>Политика в отношении файлов cookie</a>`,
            buttons: [
              {
                text: 'Принимаю',
                attrs: { variant: 'outline-default' },
                handler(button, idx) {
                  localStorage.setItem('cookiePolicy', 'agreed');
                },
              },
              {
                text: 'Нет',
                attrs: { variant: 'danger' },

                handler: async (button, idx) => {
                  this.setWindow({
                    text:
                      'Вы точно хотите отключить cookie? При отключении cookie сайт может работать некорректно.',
                    iconPath: alertGreyIcon,
                    buttons: [
                      {
                        text: 'Я передумал(-а)',
                        attrs: { variant: 'warning' },
                        handler: async () => {
                          this.setWindow(null);
                        },
                      },
                      {
                        text: 'Не нужно отключать',
                        attrs: { variant: 'warning' },
                        handler: async () => {
                          this.setWindow(null);
                        },
                      },
                      {
                        text: 'Да, точно отключить.',
                        attrs: { variant: 'danger' },
                        handler: async () => {
                          setInterval(() => {
                            // eslint-disable-next-line func-names
                            document.cookie.split(';').forEach(function (c) {
                              document.cookie = c
                                .replace(/^ +/, '')
                                // eslint-disable-next-line prefer-template
                                .replace(
                                  /=.*/,
                                  // eslint-disable-next-line prefer-template
                                  '=;expires=' + new Date().toUTCString() + ';path=/'
                                );
                            });
                          }, 100);

                          localStorage.setItem('cookiePolicy', 'disagreed');
                          this.readSideNotification(0);
                          this.setWindow(null);
                        },
                      },
                    ],
                  });
                },
              },
            ],
          });
        }
      }
    },
  },
  data() {
    return {};
  },
  created() {
    this.setApiState(ENUM.INIT);
  },
  async mounted() {
    this.$bus.$on('modal-open', (val) => {
      if (val) {
        document.body.classList.add('blocked');
        return;
      }
      this.setBackMode(false);
      document.body.classList.remove('blocked');
    });
    this.$bus.$on('login', () => {
      window.location.reload();
    });
    await this.initApp();
  },
};
</script>

<style lang="scss">
.main_-_window_-_ {
  min-height: 100vh;
}
</style>
