<template>
  <div>
    <!--div class="d-flex flex-row justify-content-start align-items-center">
      <div>
        <h2>Снизьте цену за подписчика до 72%</h2>
        <p>Простое и эффективное управление рекламой у блогеров</p>
      </div>
      <b-button class="ml-3" size="lg" variant="yellow" href="/tracker/"
        >Открыть трекер рекламы</b-button
      >
    </!--div-->
    <MobilePlancsNavigation />
    <DesktopPlancsNavigation />
    <section class="reviews section">
      <div
        style="height: auto !important"
        class="heading-row heading-row--horizontal flex-wrap flex-lg-nowrap"
        :class="{ 'mode--my_reviews': filterParams && filterParams.mode === 'me' }"
      >
        <div class="d-flex flex-row justify-content-between align-items-start w-100 mb-3 mb-lg-2">
          <div class="d-flex align-items-center">
            <h2 class="reviews__title section__title p-0 pl-lg-2 mb-md-1">
              {{
                filterParams && filterParams.mode === 'me' ? 'История моих отзывов' : reviewsTitle
              }}
            </h2>
            <!--b-button
              class="d-none d-llg-block ml-3"
              :to="{ name: 'reviews-pricetable' }"
              variant="outline-default"
              >Средняя цена за подписчика по темам из отзывов</!--b-button
            -->
          </div>
          <b-button
            v-if="!(filterParams.mode === 'me')"
            style="line-height: 22.5px !important"
            :to="{ name: 'payments-settings' }"
            :variant="
              currentUser && currentUser.subscription_days_left ? 'outline-default' : 'yellow'
            "
            >{{
              currentUser && currentUser.subscription_days_left
                ? `Осталось ${currentUser.subscription_days_left} ${literal.declension(
                    currentUser.subscription_days_left,
                    'дн'
                  )} подписки`
                : 'Купить подписку на сайт'
            }}</b-button
          >
        </div>
        <div
          class="d-flex justify-content-between d-lg-none w-100 ml-lg-2 ml-lg-2 ml-xlg-2 mt-xs-2 row-btns__rev"
        >
          <div>
            <b-button variant="outline-default" class="short-btn" @click="openFilter()">
              Фильтры
            </b-button>
            <b-button
              :disabled="!currentUser || !currentUser.liked_reviews"
              @click="
                () => {
                  if (filterParams.mode === 'liked') {
                    filterParams.mode = 'all';
                  } else {
                    filterParams.mode = 'liked';
                  }
                  $refs.revTable.pageChangeHandler(1);
                }
              "
              :variant="
                currentUser && currentUser.liked_reviews
                  ? filterParams && filterParams.mode === 'liked'
                    ? 'primary'
                    : 'outline-default'
                  : 'dark'
              "
              style="height: 33.5px !important"
              class="ml-2 px-2 px-xsm-2 ml-xsm-3 d-mmd-none"
              ><div class="d-flex flex-row align-items-center text-center">
                <img
                  v-if="!currentUser || !currentUser.liked_reviews"
                  class="mr-1"
                  style="height: 19px; width: 19px; margin-bottom: 1px"
                  src="@main/assets/img/svg/like-white-outline.svg"
                  alt=""
                /><img
                  class="mr-1"
                  style="height: 19px; width: 19px; margin-bottom: 1px"
                  v-else
                  src="@main/assets/img/svg/like.svg"
                />
                <span
                  :style="{
                    color:
                      !currentUser ||
                      !currentUser.liked_reviews ||
                      (filterParams && filterParams.mode === 'liked')
                        ? 'white'
                        : 'initial',
                  }"
                  >{{ currentUser ? currentUser.liked_reviews : 0 }}
                </span>
              </div></b-button
            >
          </div>
          <b-dropdown variant="outline-default" right toggle-class="short-btn">
            <template #button-content>
              Оставить отзывы&nbsp;<b-icon-star-fill variant="warning" v-if="windowWidth > 600" />
            </template>
            <b-dropdown-item style="border-bottom: 1px solid #d5dfe4">
              <router-link :to="{ name: 'let-review' }"
                ><img class="rev_star" src="@main/assets/img/svg/star.svg" alt="" /> Оставить отзыв
                на блогера</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item
              @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'customers' })"
              >Ссылка для рекламодателей</b-dropdown-item
            >
            <b-dropdown-item
              @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'portfolio' })"
              >Ссылка для моего портфолио</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="rectangle-block-wrapper">
        <reviews-filter
          v-if="filterParams"
          v-model="filterParams"
          @disclosed="handleDisclose"
          @reset="reset"
          @search="
            () => {
              filterParams.page = 1;
              $refs.revTable.updateData();
            }
          "
        />
        <div
          style="max-height: 300px !important"
          class="d-none d-xl-flex rectangle-block only-desktop only-desktop--flex leave_rev-block flex-column"
        >
          <div class="column leave-review mt-1">
            <img
              src="../../assets/godfather_image.jpg"
              alt=""
              class="leave-review__image mx-auto"
            />
            <router-link :to="{ name: 'let-review' }" class="btn btn-info text-nowrap mt-3"
              >Оставить отзыв на блогера</router-link
            >
          </div>
          <b-button
            @click="
              () => {
                if (!currentUser) {
                  toggleSignIn(true);
                  $router.replace({ query: { next_page: '/fillstat' } });
                  return;
                }
                $router.push('/fillstat');
              }
            "
            class="fillstat-btn mt-4"
            variant="outline-default"
            >🚀 Заполнить статистику</b-button
          >
        </div>
      </div>
    </section>

    <div class="d-flex justify-content-end my-3" v-if="!isMobile">
      <b-button
        variant="outline-secondary"
        class="bg-white mr-3"
        @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'customers' })"
      >
        Добавить отзывы на мой аккаунт
      </b-button>
      <b-button
        variant="outline-secondary"
        class="bg-white"
        @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'portfolio' })"
      >
        Отзывы для моего портфолио</b-button
      >
    </div>
    <review-table
      :key="JSON.stringify($route.query)"
      v-if="filterParams"
      ref="revTable"
      @show-stat="
        (instaname) => {
          toggleStatisticCardModal({ open: true, context: { instaname } });
        }
      "
      :disclosed="disclosed"
      :blogger="$route.query.advertiser"
      v-model="filterParams"
      ><template #post-header-mobile>
        <div class="only-mobile button-fill-stat__mobile p-3">
          <slot name="post-header"></slot>
          <p class="text-center mb-2">
            Отзывы на блогеров, у которых есть карточка на сайте, идут первыми
          </p>
          <b-button
            style="width: 100%"
            @click="() => $router.push('/fillstat')"
            variant="outline-default"
            >🚀 Заполнить карточку блогера</b-button
          >
        </div></template
      ></review-table
    >

    <generate-blogger-link-modal />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import reviewsApi from '@main/api/reviews';
import literal from '@main/func/literal';
import MobilePlancsNavigation from '@main/components/elements/navigation/MobilePlancsNavigation.vue';
import DesktopPlancsNavigation from '@main/components/elements/navigation/DesktopPlancsNavigation.vue';
import EventBus from '../../event-bus';
import ReviewsFilter from './Filter.vue';
import ReviewTable from './ReviewsTable.vue';
import GenerateBloggerLinkModal from './GenerateBloggerLinkModal.vue';
import '../../styles/reviews_list.scss';

export default {
  components: {
    ReviewsFilter,
    ReviewTable,
    GenerateBloggerLinkModal,
    MobilePlancsNavigation,
    DesktopPlancsNavigation,
  },
  computed: {
    ...mapState(['currentUser']),
    reviewsTitle() {
      if (this.isMobile || !this.reviewsCount) {
        return 'Отзывы';
      }
      return `${this.reviewsCount.toLocaleString().replaceAll(',', ' ')} ${literal.declension(
        this.reviewsCount,
        'отзыв'
      )} на рекламу у блогеров`;
    },
  },

  data() {
    return {
      isMobile,
      windowWidth: null,
      reviewsCount: 0,
      reviewsCountInterval: null,
      filterParams: null,
      disclosed: false,
    };
  },

  methods: {
    ...mapMutations('reviewsList', ['toggleGenerateBloggerLinkModal']),
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    openFilter() {
      EventBus.$emit('openReviewsFilter');
    },
    handleDisclose(val) {
      this.disclosed = val;
    },
    async setReviewsCount() {
      const res = await reviewsApi.fnc({ actions: ['count'] }, { accepted: true });
      this.$set(this, 'reviewsCount', res.result);
    },
    updateQueryFilter() {
      if (this.$route.query) {
        const keys = Object.keys(reviewsApi.defaultFilterParams);
        let params = {};
        if (keys) {
          for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            let val = this.$route.query[key];
            if (key === 'executor' && !val) {
              val = this.$route.query.advertiser;
            }
            if (val) {
              params = { ...params, [key]: val };
            }
          }
        }
        return params;
      }
    },
    async reset() {
      await this.$set(this, 'filterParams', this.lodash.cloneDeep(reviewsApi.defaultFilterParams));
      await this.$refs.revTable.updateData();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.toggleGenerateBloggerLinkModal({ value: false });
    next();
  },
  async mounted() {
    this.filterParams = {
      ...this.lodash.cloneDeep(reviewsApi.defaultFilterParams),
      ...this.updateQueryFilter(),
    };
    this.windowWidth = document.documentElement.clientWidth;
    await this.setReviewsCount();
  },
  watch: {
    '$route.query': {
      deep: true,
      handler(val) {
        this.filterParams = { ...reviewsApi.defaultFilterParams, ...this.updateQueryFilter() };
      },
    },
  },
};
</script>
