import qs from 'qs';
import lodash from 'lodash';
import apiService from './index';
import instagram from './instagram';

const BLOGGER_CARD_FILTER_PARAMS = {
  coverage_stories_min: null,
  coverage_stories_max: null,
  coverage_posts_min: null,
  coverage_posts_max: null,
  females_percent_min: null,
  females_percent_max: null,
  city: null,
  city_percent: null,
  country: null,
  country_percent: null,
  audience: null,
  audience_percent: null,
  screens_last_updated: null,
};

export default {
  defaultFilterParams: {
    executor: '',
    page: 1,
    page_size: 50,
    ad_type: '',
    price_min: null,
    price_max: null,
    price_per_one__from: null,
    price_per_one__to: null,
    arrival__from: null,
    arrival__to: null,
    rate: 0,
    price_meta: [],
    tags: [],
    mode: 'all',
    item_q: '',
    customer_kind: 'blogger',
    deleted_reviews: false,
    valid_advertiser_blogger: null,
    ...BLOGGER_CARD_FILTER_PARAMS,
  },

  bans: {
    async list(params) {
      const response = await apiService.get('/api/reviews/bans/', { params });
      return response;
    },
  },
  async fnc(data, params) {
    const response = await apiService.post('/api/reviews/fnc/', data, { params });
    return response;
  },
  async list(params, { page } = {}) {
    if (page) params.page = page;
    if (
      Object.keys(lodash.pickBy(params, lodash.identity)).some((param) =>
        Object.keys(BLOGGER_CARD_FILTER_PARAMS).includes(param)
      )
    ) {
      params.valid_advertiser_blogger = true;
    }
    const keys = Object.keys(params);
    if (keys) {
      keys.forEach((key) => {
        const value = params[key];
        if (
          value &&
          typeof value === 'object' &&
          Object.prototype.hasOwnProperty.call(params[key], 'id')
        ) {
          params[key] = params[key].id;
        }
      });
    }
    const response = await apiService.get('/api/reviews/', {
      params,
      paramsSerializer: (ps) => {
        return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
      },
      raxConfig: {
        retry: 3,
        retryDelay: 5000,
      },
    });
    return response;
  },
  async get(id) {
    const response = await apiService.get(`/api/reviews/${id}/`);
    return response;
  },
  async create(data) {
    // eslint-disable-next-line camelcase
    let advertiser_instagram_id = null;
    try {
      const grph = await instagram.getAccountGraphql(data.advertiser);
      // eslint-disable-next-line camelcase
      advertiser_instagram_id = grph.graphql.user.id;
    } catch (e) {
      console.log(e);
    }
    const response = await apiService.post('/api/reviews/', { ...data, advertiser_instagram_id });
    return response;
  },
  async update(data) {
    const response = await apiService.patch(`/api/reviews/${data.id}/`, data);
    return response;
  },
  async delete(id) {
    const response = apiService.delete(`/api/reviews/${id}/`);
    return response;
  },
  async checkMore(advertisers, customerKind) {
    const response = await apiService.post('/api/reviews/check-more/', {
      advertisers,
      customer_kind: customerKind,
    });
    return response;
  },
  async count(instaname) {
    const response = await apiService.get(`/api/reviews/count/${instaname}`);
    return response;
  },
  async like(id) {
    const response = await apiService.post(`/api/reviews/${id}/like/`);
    return response;
  },
};
