<template>
  <div class="table__mobile">
    <PrTypeDisplayableMobile
      :prTypeClickHandler="prTypeClickHandler"
      :hidden="hidden"
      v-for="type in types"
      :key="`${type.id}-prTpTblMCrdMbl`"
      :prType="type"
    />
  </div>
</template>

<script>
import PrTypeDisplayableMobile from './PrTypeDisplayableMobile.vue';

export default {
  components: {
    PrTypeDisplayableMobile,
  },
  props: {
    types: {
      type: Array,
      required: true,
    },
    hidden: {
      type: Boolean,
    },
    prTypeClickHandler: {
      type: Function,
    },
  },
};
</script>
