import Vue from 'vue';
import Router from 'vue-router';
import VueYandexMetrika from 'vue-yandex-metrika';
import bloggers from '@main/api/bloggers';
import auth from '@main/api/auth';
import wrappers from '@main/func/wrappers';
import ReviewsList from '@main/components/reviews_list/ReviewsList.vue';
import payments from '@main/api/payments';
import referal from '@main/api/referal';

import permissions from './permissions';
import store from './store/index';

Vue.use(Router);

const base = process.env.NODE_ENV === 'production' ? '/' : '/vue/';

const routes = [
  {
    path: '/mutualpr',
    component: () => import('@mp/App.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@mp/views/Home.vue'),
        meta: {
          mainClass: 'mp__background',
          layout: 'container--mutualpr',
        },
      },
      {
        path: 'moderation/',
        name: 'moderation',
        component: () => import('@mp/moderation/Moderation.vue'),
        meta: {
          layout: 'container--mutualpr',
        },
      },
      {
        path: '/_',
        redirect: '/',
        meta: {
          layout: 'container--mutualpr',
        },
      },
    ],
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    async beforeEnter(to, from, next) {
      const res = await payments.sub.createPayment();
      window.location.href = res[0].PaymentURL;
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: ReviewsList,
  },
  {
    path: '/reviews/new/',
    name: 'let-review',
    component: () => import('@main/components/forms/LetReview.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reviews/new/:advertiser',
    name: 'let-review-filled',
    component: () => import('@main/components/forms/LetReview.vue'),
    props: (route) => {
      const dateTimestamp = Number.parseInt(route.query.date, 10);
      const coverage = Number.parseInt(route.query.coverage, 10);
      return {
        customer: route.query.customer,
        mutual: route.query.mutual === '1',
        date: Number.isNaN(dateTimestamp) ? null : new Date(dateTimestamp),
        coverage: Number.isNaN(coverage) ? null : coverage,
        advertiser: route.params.advertiser,
      };
    },
  },
  {
    path: '/reviews/edit/:id',
    name: 'edit-review',
    component: () => import('@main/components/forms/LetReview.vue'),
    props: true,
  },
  {
    path: '/reviews/moderation/',
    name: 'reviews-moderation',
    component: () => import('@main/components/reviews_moderation/ReviewsModeration.vue'),
    meta: { requiresAuth: true, requiresStaff: true },
    props: (route) => {
      return {
        search: route.query.search,
        user: route.query.user,
      };
    },
  },
  {
    path: '/app/statistic',
    name: 'app-statistic',
    component: () => import('@main/components/app_statistic/AppStatistic.vue'),
    meta: { requiresAuth: true, requiresStaff: true },
  },
  {
    path: '/reviews/:advertiser',
    name: 'reviews-blogger',
    component: ReviewsList,
    props: true,
  },
  {
    path: '/pricetable/',
    name: 'reviews-pricetable',
    component: () => import('@main/components/reviewprice_table/ReviewPriceTable.vue'),
  },
  {
    path: '/promobot/orders',
    name: 'promobot-orders',
    component: () =>
      import('@main/components/advertising_applications/AdvertisingApplications.vue'),
    beforeEnter: async (to, from, next) => {
      await store.dispatch('promobot/loadRefuseReasons');
      next();
    },
  },
  {
    path: '/fillstat',
    name: 'fill_statistics',
    component: () => import('@main/components/statistics_form/StatisticsForm.vue'),
    meta: {
      requiresAuth: true,
      condition: (to, from) => {
        if (!store.state.currentUser || !store.state.currentUser.blogger_profile) {
          return true;
        }
        return !store.state.currentUser.blogger_profile.filled;
      },
      layout: 'container--stat',
    },
  },
  {
    path: '/mystat',
    name: 'my_statistic',
    component: () => import('@main/components/my_statistic/MyStatistic.vue'),
    meta: {
      layout: 'container--stat mycard',
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (!store.state.currentUser.blogger_profile) {
        next('/fillstat/');
        store.commit('notifications/showMessage', {
          title: 'Перед этим действием нужно создать карточку блогера!',
          icon: 'alert-yellow',
        });
      } else if (!store.state.currentUser.blogger_profile.filled) {
        next('/fillstat/');
        store.commit('notifications/showMessage', {
          title: 'Заполните до конца вашу карточку блогера!',
          icon: 'alert-yellow',
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/bloggers/:instaname',
    name: 'blogger_statistic',
    component: () => import('@main/components/blogger_statistic/BloggerStatistic.vue'),
    props: (route) => {
      return {
        instaname: route.params.instaname,
        access: route.query.access,
      };
    },
    meta: {
      layout: 'container--stat',
    },
  },
  {
    path: '/statistic/moderation/',
    name: 'statistic-moderation',
    component: () => import('@main/components/moderation/Moderation.vue'),
    meta: {
      requiresStaff: true,
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'statistic-settings',
    component: () => import('@main/components/settings/Settings.vue'),
    meta: {
      layout: 'container--settings',
      requiresAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      const { token } = to.query;
      if (token) {
        const response = await bloggers.connectBlogger(token);
        await store.dispatch('loadUser');
        if (response.success) {
          store.commit('notifications/showMessage', {
            title: 'Карточка успешно привязана!',
            message:
              'Внимание! Если настройки карточки блогера не появились - перезагрузите страницу.',
            icon: 1,
          });
        }
      } else {
        await store.dispatch('loadUser');
      }
      next();
    },
  },
  {
    path: '/settings/payments/',
    name: 'payments-settings',
    meta: {
      reloadUser: true,
    },
    component: () => import('@main/components/settings/subscription/ManageSubscription.vue'),
  },
  {
    path: '/confirm_email/:token',
    name: 'confirm-email',
    beforeEnter: async (to, from, next) => {
      wrappers.requiresAuth(async () => {
        const confirmation = await auth.checkEmail(to.params.token);
        try {
          if (confirmation.success) {
            store.commit('notifications/showMessage', {
              title: 'Почта подтверждена',
              icon: 1,
            });
            await store.dispatch('loadUser');
          } else {
            store.commit('notifications/showMessage', {
              title: 'Ссылка недействительна!',
              icon: 2,
            });
          }
        } catch (e) {
          console.log(e);
          store.commit('notifications/showMessage', {
            title: 'Ошибка! Повторите позднее',
            icon: 1,
          });
        }
        next({ path: to.query.next || '/reviews' });
      }, to.fullPath);
    },
  },
  {
    path: '/subscribtion/demo',
    name: 'subscription-demo',
    component: () => import('@main/components/ad_pages/SubscribeAdvantagesAdPage.vue'),
  },
  {
    path: '/subscription/demo',
    name: 'subscribtion-demo',
    component: () => import('@main/components/ad_pages/SubscribeAdvantagesAdPage.vue'),
  },
  {
    path: '/gathering/main',
    name: 'advertising-gathering',
    component: () => import('@main/components/advertising_gathering/AdvertisingGathering.vue'),
  },
  {
    path: '/gatherings/main',
    name: 'advertising-gatherings',
    component: () => import('@main/components/advertising_gathering/AdvertisingGathering.vue'),
  },
  {
    path: '/blogger_checker',
    name: 'blogger-checker',
    component: () => import('@main/components/blogger_checker/BloggerChecker.vue'),
  },
  {
    path: '/arrival_predict',
    name: 'arrival-predict',
    component: () => import('@main/components/arrival_predict/ArrivalPredict.vue'),
  },
  {
    path: '/payments/subscribe',
    name: 'subscribe',
    beforeEnter(to, from, next) {
      const response = payments.sub.createPayment(to.query.rate);
    },
  },
  {
    path: '/referal',
    name: 'referal',
    component: () => import('@main/components/referal/Referal.vue'),
  },
  {
    path: '/subscription_success',
    name: 'subscription-success',
    component: () => import('@main/components/subscription_success/SubscriptionSuccess.vue'),
  },
  {
    path: '/easyprbot_*',
    beforeEnter(to, from, next) {
      window.location.href = 'https://sub.easyprbot.com';
    },
  },
  {
    path: '/referal_*',
    async beforeEnter(to, from, next) {
      await referal.code.setRefererCode(to.params.pathMatch);
      next('/settings/payments/');
    },
  },
  {
    path: '/research',
    component: () => import('@main/components/research/ResearchView.vue'),
    children: [
      {
        path: 'instagram_funnel',
        component: () => import('@main/components/research/InstagramFunnelResearch.vue'),
        meta: {
          requiresStaff: true,
        },
      },
    ],
  },
  {
    path: '/promobot/orders/cart',
    component: () => import('@main/components/order_cart/OrderCart.vue'),
  },
  {
    path: '/promobot/orders/answers/',
    component: () => import('@main/components/order_answers/OrderAnswers.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@main/components/errors/PageNotFound.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@main/components/errors/ServerError.vue'),
  },
  {
    path: '/_',
    redirect: '/',
  },
  {
    path: '/',
    redirect: { name: 'reviews' },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

export const router = new Router({
  mode: 'hash',
  base,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to);
  await store.dispatch('apiLoaded');
  if (to.matched.some((record) => record.meta.reloadUser)) {
    await store.dispatch('loadUser');
  }
  if (to.matched.some((record) => record.meta.requiresStaff)) {
    if (!permissions.isStaff()) {
      console.log('havent staff');
      store.commit('notifications/showMessage', {
        title: 'У вас недостаточно прав для перехода на эту страницу',
        icon: 'alert-yellow',
      });
      next({
        name: 'reviews',
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!permissions.isAuthorised()) {
      store.commit('toggleSignIn', true);
      next({
        name: from.name,
        query: { next_page: to.path, ...to.query },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.onCondition)) {
    if (!to.meta.condition(to, from)) {
      next({ name: 'reviews' });
    }
  } else {
    next();
  }
});

Vue.use(VueYandexMetrika, {
  id: 55319803,
  router,
  env: process.env.NODE_ENV,
  // other options
});

export default router;
