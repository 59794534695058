import Vue from 'vue';

export default {
  setCurrentUser(state, user) {
    Vue.set(state, 'currentUser', user);
  },
  setUserCart(state, data) {
    Vue.set(state, 'userCart', data);
  },
  setUserData(state, data) {
    Vue.set(state, 'currentUser', { ...state.currentUser, ...data });
  },
  toggleSignIn(state, mode) {
    Vue.set(state, 'showSignIn', mode);
  },
  setFooterLinks(state, data) {
    Vue.set(state, 'footerLinks', data);
  },
  setTags(state, data) {
    Vue.set(state, 'tags', data);
  },
  setCountries(state, data) {
    Vue.set(state, 'countries', data);
  },
  setCities(state, data) {
    Vue.set(state, 'cities', data);
  },
  setBloggerOptions(state, data) {
    Vue.set(state, 'bloggerOptions', data);
  },
  setApiState(state, data) {
    Vue.set(state, 'apiState', data);
  },
  setBackMode(state, data) {
    Vue.set(state, 'backMode', data);
  },
  startLoading(state, attr) {
    Vue.set(state, 'loading', { ...state.loading, [attr]: true });
  },
  stopLoading(state, attr) {
    console.log('stopLoading');
    Vue.set(state, 'loading', { ...state.loading, [attr]: false });
  },
};
