<template>
  <div class="dna-loader">
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
    <div class="dna-loader-container">
      <div class="dna-loader-circle"></div>
      <div class="dna-loader-circle"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.dna-loader {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.dna-loader-container {
  height: 25px;
  margin: 0 4px;
  background-color: blue;
  position: relative;
}

.dna-loader-container .dna-loader-circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 3px);
  top: 0;
}

.dna-loader-container .dna-loader-circle:nth-child(1) {
  top: 0;
  animation: anim_a 2s infinite ease-in-out;
  background-color: #dc9bf6;
}

.dna-loader-container .dna-loader-circle:nth-child(2) {
  top: calc(100% - 5px);
  transform: scale(1.5);
  animation: anim_b 2s infinite ease-in-out;
  background-color: #a7d0ff;
}

@keyframes anim_a {
  0% {
    top: 0;
    z-index: 3;
  }

  20% {
    transform: scale(1.5);
  }

  50% {
    top: 100%;
  }

  50.1% {
    z-index: 0;
  }

  75% {
    transform: scale(0.8);
    z-index: 0;
  }

  100% {
    top: 0%;
    z-index: 2;
  }
}

@keyframes anim_b {
  0% {
    top: calc(100% - 5px);
    z-index: 1;
  }

  25% {
    transform: scale(0.8);
  }

  50% {
    top: 0;
  }

  50.1% {
    z-index: 2;
  }

  80% {
    transform: scale(1.5);
    z-index: 2;
  }

  100% {
    top: calc(100% - 5px);
    z-index: 0;
  }
}

.dna-loader-container:nth-child(2) .dna-loader-circle {
  animation-delay: -0.1s;
}

.dna-loader-container:nth-child(3) .dna-loader-circle {
  animation-delay: -0.2s;
}

.dna-loader-container:nth-child(4) .dna-loader-circle {
  animation-delay: -0.3s;
}

.dna-loader-container:nth-child(5) .dna-loader-circle {
  animation-delay: -0.4s;
}

.dna-loader-container:nth-child(6) .dna-loader-circle {
  animation-delay: -0.5s;
}

.dna-loader-container:nth-child(7) .dna-loader-circle {
  animation-delay: -0.6s;
}

.dna-loader-container:nth-child(8) .dna-loader-circle {
  animation-delay: -0.7s;
}

.dna-loader-container:nth-child(9) .dna-loader-circle {
  animation-delay: -0.8s;
}

.dna-loader-container:nth-child(10) .dna-loader-circle {
  animation-delay: -0.9s;
}

.dna-loader-container:nth-child(11) .dna-loader-circle {
  animation-delay: -1s;
}

.dna-loader-container:nth-child(12) .dna-loader-circle {
  animation-delay: -1.1s;
}
</style>
